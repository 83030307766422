import React from "react";
import Plan from "../Plan";

const OverUsage = ({ userPlan, setCurrentPlan }) => {
	const overUsagePrice = (storage, bandwidth) => {
		if (userPlan.plan_id === 1 || userPlan.plan_id === 4) {
			return (
				0.7 * (storage / Math.pow(10, 9)) +
				0.1 * (bandwidth / Math.pow(10, 9))
			);
		} else if (
			userPlan.plan_id === 2 ||
			userPlan.plan_id === 5 ||
			userPlan.plan_type === "custom"
		) {
			return (
				0.7 * (storage / Math.pow(10, 9)) +
				0.08 * (bandwidth / Math.pow(10, 9))
			);
		} else if (userPlan.plan_id === 3 || userPlan.plan_id === 6) {
			return (
				0.7 * (storage / Math.pow(10, 9)) +
				0.06 * (bandwidth / Math.pow(10, 9))
			);
		}
	};

	return (
		<div className="md:p-7 p-3">
			<p className=" font-bold text-xl">Over Usage</p>
			<div className="mt-5 p-5 bg-gray">
				<Plan
					id="over"
					period={false}
					name="Over Usage"
					customize={true}
					setCurrentPlan={setCurrentPlan}
					overusage={true}
					min={10 * Math.pow(10, 9)}
					max={500 * Math.pow(10, 9)}
					min2={100 * Math.pow(10, 9)}
					max2={2000 * Math.pow(10, 9)}
					step1={10 * Math.pow(10, 9)}
					step2={100 * Math.pow(10, 9)}
					setPrice={overUsagePrice}
				/>
				<p className=" font-bold font-xl mt-5 md:w-1/2">
					* Storage and Bandwidth you buy over your plan are valid up
					to your current plan duration{" "}
					<span className="text-yellow">{userPlan.end_date}</span>
				</p>
			</div>
		</div>
	);
};

export default OverUsage;

import React, { useEffect, useState } from "react";
import VideoOption from "../VideoOption";
import { getFormData } from "../../utils/getFormData";
import { getUser } from "../../utils/getUser";
import axios from "axios";

const AvaliableResloution = ({ available_resolutions }) => {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);
	const [done, setDone] = useState(false);

	useEffect(() => {
		if (done) {
			setTimeout(() => {
				setDone(false);
			}, 2000);
		}
	}, [done]);

	const resloutionSubmit = (e) => {
		e.preventDefault();
		setLoading(true);
		setDone(false);
		setError(false);
		const data = getFormData(e);

		let formatedData = "";
		data.forEach((d) => {
			if (d.value) {
				formatedData += `${d.name},`;
			}
		});

		const user = getUser();
		const formData = new FormData();
		formData.append("user_id", user);
		formData.append("available_resolutions", formatedData.slice(0, -1));

		axios
			.post("/edit_available_resolutions.php", formData)
			.then((response) => {
				if (response.data.status === "success") {
					setDone(true);
				} else {
					setError("An error occurred, Please try again!");
				}
			})
			.catch(() => {
				setError("An error occurred, Please try again!");
			})
			.finally(() => {
				setLoading(false);
			});
	};
	return (
		<div className="flex flex-col">
			<p className="font-bold text-xl mb-5">Available Resloution</p>
			<form
				className="p-5 bg-gray flex-1 flex flex-col gap-5 *:font-bold button-lg-no-active"
				onSubmit={resloutionSubmit}
			>
				<VideoOption
					name="240P - 600kbps"
					status={available_resolutions.includes("240p")}
					keyName="240p"
				/>
				<VideoOption
					name="360P - 800kbps"
					status={available_resolutions.includes("360p")}
					keyName="360p"
				/>
				<VideoOption
					name="480P - 1400kbps"
					status={available_resolutions.includes("480p")}
					keyName="480p"
				/>
				<VideoOption
					name="720P - 2800kbps"
					status={available_resolutions.includes("720p")}
					keyName="720p"
				/>
				<VideoOption
					name="1080P - 5000kbps"
					status={available_resolutions.includes("1080p")}
					keyName="1080p"
				/>
				<VideoOption
					name="1440P - 8000kbps"
					status={available_resolutions.includes("1440p")}
					keyName="1440p"
				/>
				<VideoOption
					name="2160P - 25000kbps"
					status={available_resolutions.includes("2160p")}
					keyName="2160p"
				/>
				<input
					type="submit"
					className="button-lg p-3"
					value={loading ? "LOADING..." : "UPDATE"}
					disabled={loading}
				/>
				{error && <p className="text-pink">{error}</p>}
				{done && (
					<p className="text-green">
						Avaliable Resloution has been updated!
					</p>
				)}
			</form>
		</div>
	);
};

export default AvaliableResloution;

import React from "react";
import "../wolvy.css";

const Wolvy = () => {
	return (
		<div className="wolf">
			<div className="tails">
				<div className="tail-container">
					<div className="block-div tail"></div>
					<div className="block-div"></div>
				</div>
			</div>
			<div className="empty"></div>
			<div className="block-div ear"></div>
			<div className="block-div ear"></div>
			<div className="empty"></div>
			<div className="empty"></div>
			<div className="empty"></div>
			<div className="block-div back"></div>
			<div className="block-div"></div>
			<div className="block-div"></div>
			<div className="block-half"></div>
			<div className="block-div face"></div>
			<div className="empty"></div>
			<div className="empty"></div>
			<div className="block-div back-leg"></div>
			<div className="empty"></div>
			<div className="block-div front-leg"></div>
		</div>
	);
};

export default Wolvy;

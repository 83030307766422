import React, { useEffect, useState } from "react";
import { computeFilter } from "../utils/use-filter";

const Avatar = ({
	dimensions = "10rem",
	background = "#f7ee7f",
	fill = "#000",
	setActiveAvatar,
	parent = false,
	padding = 3,
}) => {
	const [filter, setFilter] = useState(computeFilter(fill));

	// Recompute filter only when `fill` changes
	useEffect(() => {
		const newFilter = computeFilter(fill);
		setFilter(newFilter);
	}, [fill]);

	const handleClick = () => {
		if (!parent && setActiveAvatar) {
			setActiveAvatar({ background, fill });
		}
	};

	return (
		<div
			className="rounded-full flex justify-center items-center cursor-pointer"
			style={{
				height: dimensions,
				width: dimensions,
				background,
				padding: `${padding * 0.25}rem`,
			}}
			onClick={handleClick}
		>
			<img src="/images/logo.svg" alt="Logo" style={{ filter }} />
		</div>
	);
};

export default Avatar;

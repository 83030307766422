import React, { useEffect, useRef } from "react";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link, useLoaderData } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import File from "../components/File";
import Status from "../components/Status";
import AnalyticChart from "../components/AnalyticChart";

const responsive = {
	0: { items: 1 },
	768: { items: 2 },
	1024: { items: 4 },
};

const Dashboard = () => {
	const result = useLoaderData();
	const videos = result[1];
	const carouselRef = useRef(null);
	const adjustHeight = () => {
		const items =
			carouselRef.current.container.querySelectorAll(".carousel-item");
		const heights = Array.from(items).map((item) => item.scrollHeight);
		const maxHeight = Math.max(...heights);

		carouselRef.current.container.style.height = `${maxHeight}px`;
	};

	useEffect(() => {
		if (carouselRef.current) {
			adjustHeight();
			window.addEventListener("resize", adjustHeight);
			return () => {
				window.removeEventListener("resize", adjustHeight);
			};
		}
	}, [carouselRef]);

	return (
		<div className="bg-purpleSecondary h-full">
			<p className="text-4xl  tracking-widest md:p-7 p-3 border-b-[1px] border-gray">
				Dashboard
			</p>
			<div className="md:p-7 p-3 flex flex-col gap-3">
				<AnalyticChart result={result} />
			</div>
			<Status />
			<div className="md:p-7 p-3">
				<p className=" text-lg font-bold mb-2">Recent Videos</p>

				{videos.videos?.length ? (
					<div className="pt-10 bg-gray">
						<OwlCarousel
							className="owl-theme"
							margin={10}
							nav
							dots={false}
							responsive={responsive}
							ref={carouselRef}
						>
							{videos.videos.map((v, i) => (
								<File skipStatus={true} key={i} {...v} />
							))}
						</OwlCarousel>
						<div className="flex justify-end p-5">
							<Link to="/folders" className=" text-xl font-bold">
								See more{" "}
								<FontAwesomeIcon icon={faChevronRight} />
							</Link>
						</div>
					</div>
				) : (
					<p className="py-10 text-center font-bold  text-2xl bg-gray">
						No Videos uploaded yet.
					</p>
				)}
			</div>
		</div>
	);
};

export default Dashboard;

import React from "react";

const VideoPreview = ({ data, setEmbedVideo, setSubtitle, setDeleteVideo }) => {
	return (
		<div className="flex justify-between flex-col gap-5">
			<div className="flex">
				<div className="button-lg w-full">
					<iframe
						title={data.title}
						src={
							data.iframe_url
								.split("video.wolvy.stream")
								.join("iframe.mediadelivery.net/embed") +
							"?autoplay=false&responsive=true"
						}
						className="h-72 w-full object-contain"
						allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture;"
						allowFullScreen="true"
					/>
				</div>
			</div>
			<div
				className="button-lg justify-center w-full p-4 flex gap-5 items-center"
				onClick={() => setEmbedVideo(true)}
			>
				<img
					src="/images/code.svg"
					className="h-5 invert brightness-[2%]"
					alt="Code"
				/>
				<p className="font-bold">EMBED VIDEO</p>
			</div>
			{data.original_link && (
				<button
					onClick={async () => {
						try {
							const response = await fetch(data.original_link);
							const blob = await response.blob();
							const link = document.createElement("a");
							link.href = URL.createObjectURL(blob);
							link.download = data.title;
							link.click();
							URL.revokeObjectURL(link.href);
						} catch (error) {
							console.error("Download failed:", error);
						}
					}}
					className="button-lg !bg-yellow justify-center w-full p-4 flex gap-5 items-center"
				>
					<img
						src="/images/code.svg"
						className="h-5 brightness-0 saturate-100"
						alt="Code"
					/>
					<p className="font-bold text-black">
						Download Original Video
					</p>
				</button>
			)}
			<div
				className="button-lg !bg-lavander justify-center w-full p-4 flex gap-5 items-center"
				onClick={() => setSubtitle(true)}
			>
				<img src="/images/subtitle.svg" className="h-5" alt="Code" />
				<p className="font-bold text-black">UPLOAD SUBTITLES</p>
			</div>
			<div
				className="button-lg !bg-[#e83f6f] justify-center w-full p-4 flex gap-5 items-center"
				onClick={() => setDeleteVideo(true)}
			>
				<img
					src="/images/delete.svg"
					className="h-5 brightness-0 saturate-100"
					alt="Code"
				/>
				<p className="font-bold text-black">DELETE VIDEO</p>
			</div>
		</div>
	);
};

export default VideoPreview;

import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const TimeItem = ({
	formatTime,
	id,
	label,
	intervals,
	setChapters,
	setIntervals,
}) => {
	const deleteTime = (e) => {
		e.preventDefault();
		setChapters((h) => h.filter((i) => i.id !== id));
		setIntervals((item) =>
			item.filter(
				(i) => !(i.start === intervals.start && i.end === intervals.end)
			)
		);
	};
	return (
		<form
			className="flex items-stretch gap-5 md:flex-nowrap flex-wrap"
			onSubmit={deleteTime}
		>
			<input
				type="text"
				disabled={true}
				className="p-2 bg-grayLight font-normal w-20"
				defaultValue={formatTime(intervals.start)}
			/>
			<input
				type="text"
				disabled={true}
				className="p-2 bg-grayLight font-normal w-20"
				defaultValue={formatTime(intervals.end)}
			/>
			<input
				type="text"
				disabled={true}
				className="p-2 bg-grayLight font-normal flex-1"
				defaultValue={label}
			/>
			<button type="submit" className="button-lg h-10 w-10 !bg-pink">
				<FontAwesomeIcon icon={faTimes} />
			</button>
		</form>
	);
};

export default TimeItem;

import React, { useState, useEffect, useRef } from "react";
import { getTrackBackground, Range } from "react-range";
import TimeInput from "./TimeInput";
import AlphabetIdGenerator from "../utils/AlphabetId";
import TimeItem from "./TimeItem";
import axios from "axios";
import { compareArrays } from "../utils/compareArrays";
import { getUser } from "../utils/getUser";
import {
	formatTime,
	formatChaptersData,
	formatIntervalData,
	getAvailableIntervals,
} from "../utils/timePicker";

const TimeIntervalPicker = ({ chaptersData, videoID, duration, setData }) => {
	const [intervals, setIntervals] = useState(
		formatIntervalData(chaptersData)
	);
	const [chapters, setChapters] = useState(formatChaptersData(chaptersData));
	const [loading, setLoading] = useState(false);
	const [start, setStart] = useState(0);
	const [end, setEnd] = useState(0);
	const [error, setError] = useState("");
	const [done, setDone] = useState("");
	const [rangeBackground, setRangeBackground] = useState("");

	useEffect(() => {
		if (done) {
			setTimeout(() => {
				setDone(false);
			}, 2000);
		}
	}, [done]);

	const idGenerator = useRef(new AlphabetIdGenerator());
	const videoDuration = duration;

	const handleChapters = async () => {
		setLoading(true);
		setError(false);
		setDone(false);

		try {
			const user = getUser();
			const { removed, added } = compareArrays(
				formatChaptersData(chaptersData),
				chapters
			);

			const removeEndpoint = "/delete_chapter.php";
			const addEndpoint = "/add_chapter.php";

			let response;

			for (const chapter of removed) {
				const formData = new FormData();
				formData.append("user_id", user);
				formData.append("video_id", videoID);
				formData.append("start_time", chapter.intervals.start);
				formData.append("end_time", chapter.intervals.end);
				response = await axios.post(removeEndpoint, formData);
			}

			for (const chapter of added) {
				const formData = new FormData();
				formData.append("user_id", user);
				formData.append("video_id", videoID);
				formData.append("chapter_name", chapter.label);
				formData.append("start_time", chapter.intervals.start);
				formData.append("end_time", chapter.intervals.end);
				response = await axios.post(addEndpoint, formData);
			}
			if (response) {
				if (response.data.status === "success") {
					setData((data) => {
						data.chapters = response.data.chapters;
						return data;
					});
					setDone(true);
				} else {
					setError("An error occurred, Please try again!");
				}
			} else {
				setDone(true);
			}
		} catch (error) {
			setError("An error occurred, Please try again!");
		} finally {
			setLoading(false);
		}
	};

	const handleAddInterval = (newInterval, label) => {
		setIntervals([...intervals, newInterval]);
		setChapters([
			...chapters,
			{
				id: idGenerator.current.generateId(),
				label,
				intervals: newInterval,
			},
		]);
	};

	useEffect(() => {
		updateRangeBackground();
	}, [intervals]);

	const updateRangeBackground = () => {
		if (intervals.length === 0) {
			setRangeBackground("");
			return;
		}

		const sortedIntervals = [...intervals].sort(
			(a, b) => a.start - b.start
		);
		const gradientParts = [];

		sortedIntervals.forEach((interval) => {
			const startPercent = (interval.start / videoDuration) * 100;
			const endPercent = (interval.end / videoDuration) * 100;
			gradientParts.push(
				`transparent ${startPercent}%`,
				`rgba(239, 68, 68, 0.5) ${startPercent}%`,
				`rgba(239, 68, 68, 0.5) ${endPercent}%`,
				`transparent ${endPercent}%`
			);
		});

		setRangeBackground(
			`linear-gradient(to right, ${gradientParts.join(", ")})`
		);
	};

	const validateNewInterval = (start, end) => {
		if (start >= end) {
			setError("End time must be after start time");
			return false;
		}

		const hasOverlap = intervals.some(
			(interval) =>
				(start >= interval.start && start < interval.end) ||
				(end > interval.start && end <= interval.end) ||
				(start <= interval.start && end >= interval.end)
		);

		if (hasOverlap) {
			setError("Time interval overlaps with existing intervals");
			return false;
		}

		setError("");
		return true;
	};

	const handleTimeChange = (value, isStart) => {
		const newTime = Math.min(Math.max(0, value), videoDuration);
		const isInvalidTime = intervals.some(
			(interval) => newTime >= interval.start && newTime <= interval.end
		);
		if (!isInvalidTime) {
			if (isStart) {
				setStart(newTime);
				if (newTime >= end)
					setEnd(Math.min(newTime + 1, videoDuration));
			} else {
				setEnd(newTime);
				if (newTime <= start) setStart(Math.max(0, newTime - 1));
			}
			setError("");
		}
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		if (!e.target.label.value) {
			setError("Label is required");
			return;
		}
		if (validateNewInterval(start, end)) {
			handleAddInterval({ start, end }, e.target.label.value);
			e.target.label.value = "";
			setStart(0);
			setEnd(0);
		}
	};

	return (
		<>
			<form onSubmit={handleSubmit} className="space-y-2">
				<div className="grid md:grid-cols-2 grid-cols-1 gap-4">
					<div>
						<label
							htmlFor="start"
							className="block text-sm font-medium text-gray-300 mb-2"
						>
							Start Time
						</label>
						<div className="relative">
							<div
								className="absolute inset-0 pointer-events-none"
								style={{ background: rangeBackground }}
							/>
							<Range
								id="start"
								min={0}
								max={videoDuration}
								values={[start]}
								onChange={(e) =>
									handleTimeChange(Number(e), true)
								}
								renderTrack={({ props, children }) => (
									<div
										onMouseDown={props.onMouseDown}
										onTouchStart={props.onTouchStart}
										style={{
											...props.style,
											height: "36px",
											display: "flex",
											width: "100%",
										}}
									>
										<div
											ref={props.ref}
											style={{
												height: "5px",
												width: "100%",
												background: getTrackBackground({
													values: [start],
													colors: [
														"#f7ee7f",
														"#201226",
													],
													min: 0,
													max: videoDuration,
												}),
												alignSelf: "center",
											}}
										>
											{children}
										</div>
									</div>
								)}
								renderThumb={({ props }) => (
									<div
										{...props}
										key={props.key}
										style={{
											...props.style,
											height: "15px",
											width: "15px",
											backgroundColor: "#f7ee7f",
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
										}}
									></div>
								)}
							/>
						</div>
						<TimeInput
							value={start}
							onChange={(value) => handleTimeChange(value, true)}
							max={videoDuration}
							label="Start time"
						/>
					</div>

					<div>
						<label
							htmlFor="end"
							className="block text-sm font-medium text-gray-300 mb-2"
						>
							End Time
						</label>
						<div className="relative">
							<div
								className="absolute inset-0 pointer-events-none"
								style={{ background: rangeBackground }}
							/>
							<Range
								id="end"
								min={0}
								max={videoDuration}
								values={[end]}
								onChange={(e) =>
									handleTimeChange(Number(e), false)
								}
								renderTrack={({ props, children }) => (
									<div
										onMouseDown={props.onMouseDown}
										onTouchStart={props.onTouchStart}
										style={{
											...props.style,
											height: "36px",
											display: "flex",
											width: "100%",
										}}
									>
										<div
											ref={props.ref}
											style={{
												height: "5px",
												width: "100%",
												background: getTrackBackground({
													values: [end],
													colors: [
														"#f7ee7f",
														"#201226",
													],
													min: 0,
													max: videoDuration,
												}),
												alignSelf: "center",
											}}
										>
											{children}
										</div>
									</div>
								)}
								renderThumb={({ props }) => (
									<div
										{...props}
										key={props.key}
										style={{
											...props.style,
											height: "15px",
											width: "15px",
											backgroundColor: "#f7ee7f",
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
										}}
									></div>
								)}
							/>
						</div>
						<TimeInput
							value={end}
							onChange={(value) => handleTimeChange(value, false)}
							max={videoDuration}
							label="End time"
						/>
					</div>
				</div>

				<div className="bg-gray-900 rounded-lg p-4">
					<h3 className="text-sm font-medium text-gray-300 mb-2">
						Available Intervals:
					</h3>
					<div className="space-y-1">
						{getAvailableIntervals(intervals, videoDuration).map(
							(interval, index) => (
								<div
									key={index}
									className="text-sm text-gray-400"
								>
									{formatTime(interval.start)} -{" "}
									{formatTime(interval.end)}
								</div>
							)
						)}
					</div>
				</div>
				<input
					type="text"
					className="w-full p-2 bg-grayLight"
					id="label"
					name="label"
					placeholder="Label"
					onInput={() => setError("")}
				/>
				{error && <div className="text-[#e83f6f] text-sm">{error}</div>}
				<button
					type="submit"
					className="w-full text-white font-medium py-2 px-4 button-lg transition-colors"
					disabled={error !== ""}
				>
					Add Interval
				</button>
			</form>
			{chapters.map((h) => (
				<TimeItem
					key={h.id}
					formatTime={formatTime}
					id={h.id}
					label={h.label}
					intervals={h.intervals}
					setChapters={setChapters}
					setIntervals={setIntervals}
				/>
			))}
			<button
				className="button-lg p-2 !bg-purplePrimary"
				onClick={handleChapters}
				disabled={loading}
			>
				{loading ? "LOADING..." : "UPDATE"}
			</button>
			{done && (
				<p className="text-center mt-2 text-green">
					Data updated successfully!
				</p>
			)}
		</>
	);
};

export default TimeIntervalPicker;

import React, { useState } from "react";
import { moveToFolder } from "../utils/moveToFolder";
import { Link, useNavigate } from "react-router-dom";

const FolderItem = ({ id, status, name, fileDragged }) => {
	const [active, setActive] = useState();
	const navigate = useNavigate();
	const drop = async (e) => {
		setActive(false);
		const droped = e.dataTransfer.getData("selection");
		try {
			const data = JSON.parse(droped);
			await moveToFolder(data, id);
			navigate(`/folders/${id}`);
		} catch (error) {}
	};
	return (
		<Link
			to={`/folders/${id}`}
			className={`folder-item flex flex-col gap-2 items-center cursor-pointer *:cursor-pointer
				${active && "outline-2 outline-double outline-white"}`}
			onDrop={drop}
			onDragOver={() => fileDragged && setActive(true)}
			onDragLeave={() => fileDragged && setActive(false)}
			aria-description={id}
			aria-label={name}
		>
			<img src={`/images/${status}`} alt="Folder Item" className="h-28" />
			<p className="text-purplePrimary">{name}</p>
		</Link>
	);
};

export default FolderItem;

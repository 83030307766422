export const getLangCode = () => [
	{
		ShortCode: "af",
		Name: "Afrikaans",
		SupportPlayerTranslation: true,
		SupportTranscribing: true,
		TranscribingAccuracy: 70,
	},
	{
		ShortCode: "ar",
		Name: "Arabic",
		SupportPlayerTranslation: true,
		SupportTranscribing: true,
		TranscribingAccuracy: 70,
	},
	{
		ShortCode: "hy",
		Name: "Armenian",
		SupportPlayerTranslation: true,
		SupportTranscribing: true,
		TranscribingAccuracy: 70,
	},
	{
		ShortCode: "az",
		Name: "Azerbaijani",
		SupportPlayerTranslation: true,
		SupportTranscribing: true,
		TranscribingAccuracy: 70,
	},
	{
		ShortCode: "be",
		Name: "Belarusian",
		SupportPlayerTranslation: true,
		SupportTranscribing: true,
		TranscribingAccuracy: 70,
	},
	{
		ShortCode: "bs",
		Name: "Bosnian",
		SupportPlayerTranslation: true,
		SupportTranscribing: true,
		TranscribingAccuracy: 70,
	},
	{
		ShortCode: "bg",
		Name: "Bulgarian",
		SupportPlayerTranslation: true,
		SupportTranscribing: true,
		TranscribingAccuracy: 70,
	},
	{
		ShortCode: "ca",
		Name: "Catalan",
		SupportPlayerTranslation: true,
		SupportTranscribing: true,
		TranscribingAccuracy: 70,
	},
	{
		ShortCode: "zh",
		Name: "Chinese",
		SupportPlayerTranslation: true,
		SupportTranscribing: true,
		TranscribingAccuracy: 70,
	},
	{
		ShortCode: "hr",
		Name: "Croatian",
		SupportPlayerTranslation: true,
		SupportTranscribing: true,
		TranscribingAccuracy: 70,
	},
	{
		ShortCode: "cs",
		Name: "Czech",
		SupportPlayerTranslation: true,
		SupportTranscribing: true,
		TranscribingAccuracy: 70,
	},
	{
		ShortCode: "da",
		Name: "Danish",
		SupportPlayerTranslation: true,
		SupportTranscribing: true,
		TranscribingAccuracy: 70,
	},
	{
		ShortCode: "nl",
		Name: "Dutch",
		SupportPlayerTranslation: true,
		SupportTranscribing: true,
		TranscribingAccuracy: 70,
	},
	{
		ShortCode: "en",
		Name: "English",
		SupportPlayerTranslation: true,
		SupportTranscribing: true,
		TranscribingAccuracy: 70,
	},
	{
		ShortCode: "et",
		Name: "Estonian",
		SupportPlayerTranslation: true,
		SupportTranscribing: true,
		TranscribingAccuracy: 70,
	},
	{
		ShortCode: "fi",
		Name: "Finnish",
		SupportPlayerTranslation: true,
		SupportTranscribing: true,
		TranscribingAccuracy: 70,
	},
	{
		ShortCode: "fr",
		Name: "French",
		SupportPlayerTranslation: true,
		SupportTranscribing: true,
		TranscribingAccuracy: 70,
	},
	{
		ShortCode: "gl",
		Name: "Galician",
		SupportPlayerTranslation: true,
		SupportTranscribing: true,
		TranscribingAccuracy: 70,
	},
	{
		ShortCode: "de",
		Name: "German",
		SupportPlayerTranslation: true,
		SupportTranscribing: true,
		TranscribingAccuracy: 70,
	},
	{
		ShortCode: "el",
		Name: "Greek",
		SupportPlayerTranslation: true,
		SupportTranscribing: true,
		TranscribingAccuracy: 70,
	},
	{
		ShortCode: "he",
		Name: "Hebrew",
		SupportPlayerTranslation: true,
		SupportTranscribing: true,
		TranscribingAccuracy: 70,
	},
	{
		ShortCode: "hi",
		Name: "Hindi",
		SupportPlayerTranslation: true,
		SupportTranscribing: true,
		TranscribingAccuracy: 70,
	},
	{
		ShortCode: "hu",
		Name: "Hungarian",
		SupportPlayerTranslation: true,
		SupportTranscribing: true,
		TranscribingAccuracy: 70,
	},
	{
		ShortCode: "is",
		Name: "Icelandic",
		SupportPlayerTranslation: true,
		SupportTranscribing: true,
		TranscribingAccuracy: 70,
	},
	{
		ShortCode: "id",
		Name: "Indonesian",
		SupportPlayerTranslation: true,
		SupportTranscribing: true,
		TranscribingAccuracy: 70,
	},
	{
		ShortCode: "it",
		Name: "Italian",
		SupportPlayerTranslation: true,
		SupportTranscribing: true,
		TranscribingAccuracy: 70,
	},
	{
		ShortCode: "ja",
		Name: "Japanese",
		SupportPlayerTranslation: true,
		SupportTranscribing: true,
		TranscribingAccuracy: 70,
	},
	{
		ShortCode: "kn",
		Name: "Kannada",
		SupportPlayerTranslation: true,
		SupportTranscribing: true,
		TranscribingAccuracy: 70,
	},
	{
		ShortCode: "kk",
		Name: "Kazakh",
		SupportPlayerTranslation: true,
		SupportTranscribing: true,
		TranscribingAccuracy: 70,
	},
	{
		ShortCode: "ko",
		Name: "Korean",
		SupportPlayerTranslation: true,
		SupportTranscribing: true,
		TranscribingAccuracy: 70,
	},
	{
		ShortCode: "lv",
		Name: "Latvian",
		SupportPlayerTranslation: true,
		SupportTranscribing: true,
		TranscribingAccuracy: 70,
	},
	{
		ShortCode: "lt",
		Name: "Lithuanian",
		SupportPlayerTranslation: true,
		SupportTranscribing: true,
		TranscribingAccuracy: 70,
	},
	{
		ShortCode: "mk",
		Name: "Macedonian",
		SupportPlayerTranslation: true,
		SupportTranscribing: true,
		TranscribingAccuracy: 70,
	},
	{
		ShortCode: "ms",
		Name: "Malay",
		SupportPlayerTranslation: true,
		SupportTranscribing: true,
		TranscribingAccuracy: 70,
	},
	{
		ShortCode: "mr",
		Name: "Marathi",
		SupportPlayerTranslation: true,
		SupportTranscribing: true,
		TranscribingAccuracy: 70,
	},
	{
		ShortCode: "mi",
		Name: "Maori",
		SupportPlayerTranslation: true,
		SupportTranscribing: true,
		TranscribingAccuracy: 70,
	},
	{
		ShortCode: "ne",
		Name: "Nepali",
		SupportPlayerTranslation: true,
		SupportTranscribing: true,
		TranscribingAccuracy: 70,
	},
	{
		ShortCode: "no",
		Name: "Norwegian",
		SupportPlayerTranslation: true,
		SupportTranscribing: true,
		TranscribingAccuracy: 70,
	},
	{
		ShortCode: "fa",
		Name: "Persian",
		SupportPlayerTranslation: true,
		SupportTranscribing: true,
		TranscribingAccuracy: 70,
	},
	{
		ShortCode: "pl",
		Name: "Polish",
		SupportPlayerTranslation: true,
		SupportTranscribing: true,
		TranscribingAccuracy: 70,
	},
	{
		ShortCode: "pt",
		Name: "Portuguese",
		SupportPlayerTranslation: true,
		SupportTranscribing: true,
		TranscribingAccuracy: 70,
	},
	{
		ShortCode: "ro",
		Name: "Romanian",
		SupportPlayerTranslation: true,
		SupportTranscribing: true,
		TranscribingAccuracy: 70,
	},
	{
		ShortCode: "ru",
		Name: "Russian",
		SupportPlayerTranslation: true,
		SupportTranscribing: true,
		TranscribingAccuracy: 70,
	},
	{
		ShortCode: "sr",
		Name: "Serbian",
		SupportPlayerTranslation: true,
		SupportTranscribing: true,
		TranscribingAccuracy: 70,
	},
	{
		ShortCode: "sk",
		Name: "Slovak",
		SupportPlayerTranslation: true,
		SupportTranscribing: true,
		TranscribingAccuracy: 70,
	},
	{
		ShortCode: "sl",
		Name: "Slovenian",
		SupportPlayerTranslation: true,
		SupportTranscribing: true,
		TranscribingAccuracy: 70,
	},
	{
		ShortCode: "es",
		Name: "Spanish",
		SupportPlayerTranslation: true,
		SupportTranscribing: true,
		TranscribingAccuracy: 70,
	},
	{
		ShortCode: "sw",
		Name: "Swahili",
		SupportPlayerTranslation: true,
		SupportTranscribing: true,
		TranscribingAccuracy: 70,
	},
	{
		ShortCode: "sv",
		Name: "Swedish",
		SupportPlayerTranslation: true,
		SupportTranscribing: true,
		TranscribingAccuracy: 70,
	},
	{
		ShortCode: "tl",
		Name: "Tagalog",
		SupportPlayerTranslation: true,
		SupportTranscribing: true,
		TranscribingAccuracy: 70,
	},
	{
		ShortCode: "ta",
		Name: "Tamil",
		SupportPlayerTranslation: true,
		SupportTranscribing: true,
		TranscribingAccuracy: 70,
	},
	{
		ShortCode: "th",
		Name: "Thai",
		SupportPlayerTranslation: true,
		SupportTranscribing: true,
		TranscribingAccuracy: 70,
	},
	{
		ShortCode: "tr",
		Name: "Turkish",
		SupportPlayerTranslation: true,
		SupportTranscribing: true,
		TranscribingAccuracy: 70,
	},
	{
		ShortCode: "uk",
		Name: "Ukrainian",
		SupportPlayerTranslation: true,
		SupportTranscribing: true,
		TranscribingAccuracy: 70,
	},
	{
		ShortCode: "ur",
		Name: "Urdu",
		SupportPlayerTranslation: true,
		SupportTranscribing: true,
		TranscribingAccuracy: 70,
	},
	{
		ShortCode: "vi",
		Name: "Vietnamese",
		SupportPlayerTranslation: true,
		SupportTranscribing: true,
		TranscribingAccuracy: 70,
	},
	{
		ShortCode: "cy",
		Name: "Welsh",
		SupportPlayerTranslation: true,
		SupportTranscribing: true,
		TranscribingAccuracy: 70,
	},
];

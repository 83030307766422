import React, { useState } from "react";
import { formatBytes } from "../../utils/formatBytes";
import Pagination from "../Pagination";
import { getUser } from "../../utils/getUser";
import axios from "axios";

const DayByDay = ({ result }) => {
	const [dayByDay, setDayByDay] = useState(result[1]);
	const [error, setError] = useState(false);
	const updateDayByDay = (page) => {
		const user = getUser();
		const formData = new FormData();
		formData.append("user_id", user);
		formData.append("limit", 5);
		formData.append("page", page);
		axios
			.post("/get_daily_analytics.php", formData)
			.then((response) => {
				if (response.data.status === "success") {
					setDayByDay(response.data);
				} else {
					setError("An error occurred, Please try again!");
				}
			})
			.catch(() => {
				setError("An error occurred, Please try again!");
			});
	};
	return (
		<div className="md:p-7 p-3">
			<div className="flex justify-between items-center">
				<p className=" font-bold text-xl">Day By Day</p>
			</div>
			<div className="mt-5 pb-5 bg-gray overflow-x-auto">
				<table className="w-full">
					<thead className="text-2xl [&_td]:p-5">
						<tr className="border-b-8 border-b-[rgba(0,0,0,0.1)]">
							<td>Date</td>
							<td>Storage</td>
							<td>Bandwidth</td>
							<td>View</td>
						</tr>
					</thead>
					<tbody className="[&_td]:p-5">
						{dayByDay.data.map((d) => (
							<tr>
								<td>{d.date}</td>
								<td>{formatBytes(d.storage)}</td>
								<td>{formatBytes(d.bandwidth)}</td>
								<td>{d.views}</td>
							</tr>
						))}
					</tbody>
				</table>
				<Pagination
					pages={dayByDay.pagination.total_pages}
					current={dayByDay.pagination.current_page}
					update={updateDayByDay}
				/>
				{error && <p className="text-pink">{error}</p>}
			</div>
		</div>
	);
};

export default DayByDay;

import React, { useEffect, useState } from "react";
import { getUser } from "../../utils/getUser";
import axios from "axios";

const PersonalDetails = ({ name, email, phone }) => {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);
	const [done, setDone] = useState(false);
	useEffect(() => {
		if (done) {
			setTimeout(() => {
				setDone(false);
			}, 2000);
		}
	}, [done]);

	const handleSubmit = (e) => {
		e.preventDefault();
		setLoading(true);
		setDone(false);
		setError(false);
		const name = e.target.name.value;
		const email = e.target.email.value;
		const phone = e.target.tel.value;
		if (
			!name ||
			!email ||
			!phone ||
			name === "" ||
			email === "" ||
			phone === ""
		) {
			setError("Name, Email and Phone Number are required.");
			setLoading(false);
			return;
		}
		const user = getUser();
		const formData = new FormData();
		formData.append("user_id", user);
		formData.append("name", name);
		formData.append("email", email);
		formData.append("phone", phone);
		axios
			.post("/edit_user_details.php", formData)
			.then((res) => {
				if (res.data.status === "success") {
					setDone(true);
				} else {
					setError("An error occurred, Please try again!");
				}
			})
			.catch(() => {
				setError("An error occurred, Please try again!");
			})
			.finally(() => {
				setLoading(false);
			});
	};
	return (
		<div className="flex flex-col">
			<p className="font-bold text-xl mb-5">Personal Details</p>
			<form
				className="button-lg-no-active flex-1 p-3 bg-gray flex flex-col justify-between gap-5 [&_label]:font-bold [&_label]:text-lg [&_input]:bg-grayLight [&_input]:p-3"
				onSubmit={handleSubmit}
			>
				<div className="flex flex-col gap-2">
					<label htmlFor="name">Name</label>
					<input
						required
						type="text"
						id="name"
						name="name"
						placeholder="Name"
						defaultValue={name}
					/>
				</div>
				<div className="flex flex-col gap-2">
					<label htmlFor="email">Email</label>
					<input
						required
						type="email"
						id="email"
						name="email"
						placeholder="Email"
						defaultValue={email}
					/>
				</div>
				<div className="flex flex-col gap-2">
					<label htmlFor="tel">WhatsApp</label>
					<input
						required
						type="tel"
						id="tel"
						name="tel"
						placeholder="WhatsApp"
						defaultValue={phone}
					/>
				</div>
				<input
					type="submit"
					className="button-lg p-2 !bg-purplePrimary"
					value={loading ? "LOADING..." : "UPDATE"}
				/>
				{error && <p className="text-pink">{error}</p>}
				{done && (
					<p className="text-green">
						Personal Details has been updated!
					</p>
				)}
			</form>
		</div>
	);
};

export default PersonalDetails;

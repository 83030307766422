import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getUser } from "../utils/getUser";

const DeleteVideo = ({ videoID, videoName, setDeleteVideo }) => {
	videoID.filter((v, i) => {
		videoID.forEach((dv, di) => {
			if (dv === v && i !== di) {
				videoID.splice(i, 1);
				videoName.splice(i, 1);
				return;
			}
		});
	});

	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const handleDelete = async () => {
		setLoading(true);
		const user = getUser();
		let errors = false;
		try {
			for (const id of videoID) {
				const formData = new FormData();
				formData.append("user_id", user);
				formData.append("video_id", id);

				const response = await axios.post(
					"/delete_video.php",
					formData
				);
				setDeleteVideo(false);

				if (response.data.status === "error") {
					errors = true;
				}
			}
		} catch (error) {
			navigate("/folders?status=error");
			setDeleteVideo(false);
			return;
		}

		if (errors) {
			navigate("/folders?status=error");
		} else {
			navigate("/folders?status=done");
		}
	};
	return (
		<div className="fixed top-0 left-0 h-screen w-screen z-50 flex justify-center items-center bg-white/50">
			<div className="w-full md:w-1/3 bg-[#d4bdf1] shadow-[15px_15px_0_0_#3f1f61] border-2 border-purpleSecondary flex flex-col">
				<div className="flex bg-purplePrimary justify-end p-5 border-b-2 border-purpleSecondary">
					<img
						src="/images/o-x.png"
						className="h-6 cursor-pointer select-none"
						alt="x-o"
						onClick={() => setDeleteVideo(false)}
					/>
				</div>
				<div className="h-full py-10 flex justify-center items-center flex-col">
					<div className="flex items-center gap-5 mx-5 w-full px-3">
						<img
							src="/images/warning.png"
							className="h-20"
							alt="Warning"
						/>
						<p className="text-black text-lg overflow-auto max-w-[90%] break-words">
							You really want to delete "{videoName.join(", ")}"?
						</p>
					</div>
					<div className="flex justify-evenly mt-5 gap-10">
						<button
							disabled={loading}
							className="button-lg !bg-[#9971d1] text-black py-4 px-10"
							onClick={handleDelete}
						>
							YES
						</button>
						<button
							className="button-lg !bg-pink text-black py-4 px-10"
							onClick={() => setDeleteVideo(false)}
						>
							NO
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default DeleteVideo;

import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useRef } from "react";
import AlphabetIdGenerator from "../utils/AlphabetId";
import VideoListItem from "./VideoListItem";
import TimeTextInput from "./TimetextInput";
import { formatHighlightsData } from "../utils/formatHighlights";

const VideoList = ({
	inputs,
	label,
	placeholder,
	editable = true,
	checkLabel,
	checkInput,
	defaultValues,
	onSubmit,
	submitLoading,
	duration,
	checkDuplicated,
	domiansDefaultValues,
	IPDefaultValues,
}) => {
	const formatDomains = (domains) => {
		const data = [];
		domains.forEach((domain, i) => {
			data.push({
				id: i,
				label: domain,
			});
		});
		return data;
	};
	const [data, setData] = useState(
		defaultValues
			? formatHighlightsData(defaultValues)
			: domiansDefaultValues
			? formatDomains(domiansDefaultValues)
			: IPDefaultValues
			? formatDomains(IPDefaultValues)
			: []
	);
	const idGenerator = useRef(new AlphabetIdGenerator());
	const [seconds, setSeconds] = useState(0);
	const max = duration;

	const addItem = (e) => {
		e.preventDefault();
		if (checkLabel && !checkLabel(e.target[label].value)) {
			return;
		}
		if (checkDuplicated) {
			const filter = data.filter(
				(d) => d.label === e.target[label].value
			);
			if (filter.length) return;
		}
		const newItem = {};
		newItem.id = idGenerator.current.generateId();
		if (inputs) {
			const inputsArray = [];
			inputs.forEach((i) => {
				inputsArray.push(e.target[i].value);
				e.target[i].value = "";
			});
			if (checkInput && !checkInput(inputsArray)) {
				return;
			}
			newItem.inputs = inputsArray;
		} else {
			if (
				seconds > max ||
				data.filter((d) => d.inputs === seconds).length
			) {
				return;
			}
			newItem.inputs = seconds;
		}
		newItem.label = e.target[label].value;
		e.target[label].value = "";
		setData((data) => [...data, newItem]);
	};
	const handleTime = (e) => {
		if (e.target.value.length >= 9) {
			e.target.value = e.target.value.slice(0, 8);
		} else if (isNaN(e.target.value.slice(-1))) {
			e.target.value = e.target.value.slice(0, -1);
		}
		if (e.target.value.slice(-1) === ":") {
			e.target.value = e.target.value.slice(0, -1);
		} else if (e.target.value.length === 2 || e.target.value.length === 5) {
			e.target.value = e.target.value + ":";
		}
	};

	return (
		<div className="flex flex-col gap-5">
			<form
				className="flex items-stretch gap-5 md:flex-nowrap flex-wrap"
				onSubmit={addItem}
			>
				{inputs ? (
					inputs.map((i, key) => (
						<input
							type="text"
							className="p-2 bg-grayLight font-normal w-24"
							key={key}
							name={i}
							placeholder={i}
							required
							onInput={handleTime}
						/>
					))
				) : (
					<TimeTextInput
						defaultValue={0}
						max={max}
						onChange={(n) => setSeconds(n)}
					/>
				)}
				<input
					type="text"
					className="p-2 bg-grayLight font-normal flex-1"
					name={label}
					placeholder={placeholder || label}
					required
				/>
				<button type="submit" className="button-lg h-10 w-10">
					<FontAwesomeIcon icon={faPlus} />
				</button>
			</form>
			{data.length ? (
				<div className="flex flex-col gap-5">
					{data.map((d) => (
						<VideoListItem
							editable={editable}
							key={d.id}
							{...d}
							data={data}
							setData={setData}
							max={max}
							checkLabel={checkLabel}
							checkDuplicated={checkDuplicated}
						/>
					))}
				</div>
			) : (
				""
			)}
			{onSubmit && (
				<button
					disabled={submitLoading}
					onClick={() => onSubmit(data)}
					className="w-full text-white font-medium py-2 px-4 button-lg transition-colors"
				>
					{submitLoading ? "LOADING..." : "UPDATE"}
				</button>
			)}
		</div>
	);
};

export default VideoList;

import React, { useState } from "react";
import Status from "../components/Status";
import MapComponent from "../components/Map";
import ReactCountryFlag from "react-country-flag";
import { useLoaderData } from "react-router-dom";
import AnalyticChart from "../components/AnalyticChart";
import { formatCountriesData } from "../utils/formCountriesData";
import axios from "axios";
import { getUser } from "../utils/getUser";
import DayByDay from "../components/Analytics/DayByDay";

const Analytics = () => {
	const result = useLoaderData();
	const [countries, setCountries] = useState(formatCountriesData(result[2]));
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);

	const handleMapPeriod = (e) => {
		const period = e.target.value;
		setLoading(true);
		if (period) {
			const user = getUser();
			const formData = new FormData();
			formData.append("user_id", user);
			formData.append("aggregation", period);
			axios
				.post("/get_analytics_by_country.php", formData)
				.then((response) => {
					if (response.data.status === "success") {
						setCountries(formatCountriesData(response.data));
					} else {
						setError("An error occurred, Please try again!");
					}
				})
				.catch(() => {
					setError("An error occurred, Please try again!");
				})
				.finally(() => {
					setLoading(false);
				});
		}
	};

	return (
		<div className="bg-purpleSecondary h-full">
			<p className="text-4xl  tracking-widest md:p-7 p-3 border-b-[1px] border-gray">
				Analytics
			</p>
			<div className="md:p-7 p-3 flex flex-col gap-3">
				<AnalyticChart result={result} />
			</div>
			<Status />
			<DayByDay result={result} />
			<div className="md:p-7 p-3">
				<div className="flex justify-between items-center mb-5">
					<p className=" font-bold text-xl">Country Views</p>
					<select
						name="period"
						className="bg-black p-1 w-52"
						id="period"
						onChange={handleMapPeriod}
						disabled={loading}
					>
						<option value="year">Year</option>
						<option value="month">Month</option>
						<option value="day">Day</option>
					</select>
				</div>
				{error && <p className="text-pink">{error}</p>}
				<div className="p-5 bg-gray">
					<MapComponent countries={countries} />
					<div className="flex gap-5 mt-5 items-center flex-wrap">
						{countries.length
							? countries.map((c, i) => (
									<div
										className="flex items-center gap-3"
										key={i}
									>
										<ReactCountryFlag
											countryCode={c.id}
											style={{
												width: "3em",
												height: "2em",
											}}
											svg
										/>
										<p>
											{c.name}: {c.views} View - {c.time}
										</p>
									</div>
							  ))
							: ""}
					</div>
				</div>
			</div>
		</div>
	);
};

export default Analytics;

import { faMapMarker } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef, useState, useEffect } from "react";

const MapComponent = ({ countries }) => {
	const mapRef = useRef(null);
	const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

	const centerLat = 28;
	const centerLng = 11;

	useEffect(() => {
		const updateDimensions = () => {
			if (mapRef.current) {
				setDimensions({
					width: mapRef.current.clientWidth,
					height: mapRef.current.clientHeight,
				});
			}
		};
		updateDimensions();
		window.addEventListener("resize", updateDimensions);
		return () => window.removeEventListener("resize", updateDimensions);
	}, []);

	const placeMarker = (lat, lng) => {
		const { width, height } = dimensions;
		const x = ((lng - centerLng) / 360) * width + width / 2;
		const y = ((centerLat - lat) / 180) * height + height / 2;
		return { x, y };
	};

	return (
		<div className="w-full relative">
			<img
				ref={mapRef}
				src="/images/map.png"
				alt="Map"
				className="w-full"
			/>

			{dimensions.width > 0 && dimensions.height > 0 && (
				<>
					{countries.length
						? countries.map((c, i) => (
								<div
									key={i}
									className="absolute group"
									style={{
										top: `${
											placeMarker(c.top, c.left).y
										}px`,
										left: `${
											placeMarker(c.top, c.left).x
										}px`,
									}}
								>
									<FontAwesomeIcon
										icon={faMapMarker}
										className="*:text-purplePrimary text-3xl origin-bottom absolute bottom-0 -translate-x-1/2"
									/>
									<div className="absolute top-1 left-1/2 w-52 -translate-x-1/2 p-2 text-center text-xs bg-purplePrimary text-white hidden group-hover:block">
										{c.name}: {c.views} View - {c.time}
									</div>
								</div>
						  ))
						: ""}
				</>
			)}
		</div>
	);
};

export default MapComponent;

import React from "react";
import { getTrackBackground, Range } from "react-range";

const PlanRange = ({ step = 1, min, max, number, setNumber }) => {
	return (
		<Range
			values={number}
			step={step}
			min={min}
			max={max}
			onChange={(number) => setNumber(number)}
			renderTrack={({ props, children }) => (
				<div
					onMouseDown={props.onMouseDown}
					onTouchStart={props.onTouchStart}
					style={{
						...props.style,
						height: "36px",
						display: "flex",
						width: "80%",
					}}
				>
					<div
						ref={props.ref}
						style={{
							height: "5px",
							width: "100%",
							background: getTrackBackground({
								values: number,
								colors: ["#f7ee7f", "#201226"],
								min,
								max,
							}),
							alignSelf: "center",
						}}
					>
						{children}
					</div>
				</div>
			)}
			renderThumb={({ props }) => (
				<div
					{...props}
					key={props.key}
					style={{
						...props.style,
						height: "15px",
						width: "15px",
						backgroundColor: "#f7ee7f",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				></div>
			)}
		/>
	);
};

export default PlanRange;

import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { decode } from "../utils/crypto";

const Auth = () => {
	const [user, setUser] = useState(null);
	const navigate = useNavigate();

	useEffect(() => {
		const part1 = sessionStorage.getItem("session");
		const part2 = sessionStorage.getItem("vid");
		const decoded = decode(part1, part2);
		if (decoded && decoded !== "Error") {
			setUser(decoded);
		} else {
			sessionStorage.removeItem("session");
			sessionStorage.removeItem("vid");
			setUser("No User");
		}
	}, []);

	useEffect(() => {
		if (user === "No User") {
			navigate("/login");
		}
	}, [user, navigate]);

	if (user === null) {
		return <div>Loading...</div>;
	}

	if (user !== "No User") {
		return <Outlet />;
	}

	return null;
};

export default Auth;

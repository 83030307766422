import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

const Sidebar = ({ menu, setMenu }) => {
	const navigate = useNavigate();
	const handleLogout = () => {
		sessionStorage.clear();
		navigate("/login");
	};
	const { pathname } = useLocation();
	return (
		<aside className="h-full overflow-auto bg-gray p-3 z-20 sticky top-0">
			{menu && (
				<div className="flex justify-end">
					<FontAwesomeIcon
						icon={faTimes}
						className="text-3xl cursor-pointer"
						onClick={() => setMenu(false)}
					/>
				</div>
			)}
			<Link to="/">
				<img
					src="/images/logo.png"
					className="w-full"
					alt="Wolvy Logo"
				/>
			</Link>
			<ul className="mt-16 flex flex-col gap-3 *:flex [&_a]:w-full">
				<li>
					<Link
						to={"/dashboard"}
						className={`button p-2 uppercase 
							${pathname === "/dashboard" && "!bg-purplePrimaryDisabled"}`}
						onClick={() => menu && setMenu(false)}
					>
						Dashboard
					</Link>
				</li>
				<li>
					<Link
						to={"/folders"}
						className={`button p-2 uppercase 
							${pathname === "/folders" && "!bg-purplePrimaryDisabled"}`}
						onClick={() => menu && setMenu(false)}
					>
						Videos
					</Link>
				</li>
				<li>
					<Link
						to={"/account-and-payments"}
						className={`button p-2 uppercase 
							${pathname === "/account-and-payments" && "!bg-purplePrimaryDisabled"}`}
						onClick={() => menu && setMenu(false)}
					>
						Account & payment
					</Link>
				</li>
				<li>
					<Link
						to={"/analytics"}
						className={`button p-2 uppercase 
							${pathname === "/analytics" && "!bg-purplePrimaryDisabled"}`}
						onClick={() => menu && setMenu(false)}
					>
						analytics
					</Link>
				</li>
				<li>
					<Link
						to={"/contact-us"}
						className="button p-2 uppercase "
						onClick={() => menu && setMenu(false)}
					>
						contact us
					</Link>
				</li>
			</ul>
			<div className="mt-48 flex [&_a]:w-full">
				<div
					className="button w-full p-2 uppercase "
					onClick={() => {
						menu && setMenu(false);
						handleLogout();
					}}
				>
					logout
				</div>
			</div>
		</aside>
	);
};

export default Sidebar;

import React, { useState } from "react";
import { useLoaderData } from "react-router-dom";
import PersonalDetails from "../components/MyAccount/PersonalDetails";
import AvatarDetails from "../components/MyAccount/AvatarDetails";
import AllowedDomains from "../components/MyAccount/AllowedDomains";
import BlockedDomians from "../components/MyAccount/BlockedDomians";
import PlayerOptions from "../components/MyAccount/PlayerOptions";
import AvaliableResloution from "../components/MyAccount/AvaliableResloution";
import PlayerTheme from "../components/MyAccount/PlayerTheme";
import PlayerColor from "../components/MyAccount/PlayerColor";
import KeepOriginal from "../components/MyAccount/KeepOriginal";
import AllowedIP from "../components/MyAccount/AllowedIP";

const MyAccount = () => {
	const data = useLoaderData();
	const userData = data[0][0].data;
	const [
		{
			player_settings,
			player_color,
			available_resolutions,
			player_theme,
			keep_original,
			allowed_ips,
			allowed_domains,
			blocked_domains,
			user_color,
			user_background_color,
		},
		setData,
	] = useState(userData.settings);

	return (
		<div className="h-full bg-purpleSecondary">
			<p className="text-4xl  tracking-widest md:p-7 p-3 border-b-[1px] border-gray">
				My Account
			</p>
			<div className="md:p-7 p-3 ">
				<div className="flex gap-5 *:flex-1 md:flex-row flex-col">
					<PersonalDetails {...userData.user} />
					<AvatarDetails
						user_color={user_color}
						user_background_color={user_background_color}
					/>
				</div>
				<p className="mt-10 text-3xl font-bold mb-5">Domains</p>
				<div className="flex gap-5 *:flex-1 md:flex-row flex-col">
					<AllowedDomains
						allowed_domains={allowed_domains}
						setData={setData}
					/>
					<BlockedDomians
						blocked_domains={blocked_domains}
						setData={setData}
					/>
				</div>
				<p className="mt-10 text-3xl font-bold mb-5">Options</p>
				<div className="flex gap-5 *:flex-1 md:flex-row flex-col">
					<PlayerOptions player_settings={player_settings} />
					<AvaliableResloution
						available_resolutions={available_resolutions}
					/>
				</div>
				<div className="flex gap-5 *:flex-1 md:flex-row flex-col mt-5">
					<div>
						<PlayerTheme player_theme={player_theme} />
						<PlayerColor player_color={player_color} />
					</div>
					<div className="flex flex-col">
						<p className="font-bold text-xl mb-5">Original File</p>
						<div className="p-5 bg-gray h-full">
							<KeepOriginal keep_original={keep_original} />
							<AllowedIP
								allowed_ips={allowed_ips}
								setData={setData}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default MyAccount;

import React from "react";
import ReactCountryFlag from "react-country-flag";
import { countriesLatLong } from "../../utils/getCountriesCor";
import { convertSecondsToTime } from "../../utils/formCountriesData";

const ByCountry = ({ data }) => {
	const countries = countriesLatLong;

	return (
		<div className="flex flex-col">
			<p className="font-bold text-xl mb-5">By Country</p>
			<div className="p-5 bg-gray flex-1 button-lg-no-active flex flex-col gap-5 *:font-bold">
				{data.country_views && data.country_views.length ? (
					Object.keys(data.country_views).map((d, i) => {
						const country = countries.filter(
							(co) => co.alpha2 === d
						)[0];
						return (
							<div className="flex items-center gap-3" key={i}>
								<ReactCountryFlag
									countryCode={d}
									style={{
										width: "3em",
										height: "2em",
									}}
									svg
								/>
								<p>
									{country.country}: {data.country_views[d]}{" "}
									View -{" "}
									{convertSecondsToTime(
										data.country_watch_time[d]
									)}
								</p>
							</div>
						);
					})
				) : (
					<p>No Country Views yet!</p>
				)}
			</div>
		</div>
	);
};

export default ByCountry;

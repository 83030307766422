export const getFormData = (e) => {
	const inputs = e.target.getElementsByTagName("input");

	if (inputs) {
		let output = [];
		Array.from(inputs).forEach((i) => {
			if (i.getAttribute("type") === "hidden") {
				const item = {
					name: i.name,
					value: i.value === "true" ? true : false,
				};
				output.push(item);
			}
		});
		return output;
	}
	return [];
};

class AlphabetIdGenerator {
	constructor() {
		this.currentIndex = 0; // Track the position for generating IDs
	}

	generateId() {
		let id = "";
		let index = this.currentIndex;

		// Convert index to a base-26 alphabet sequence (like 'a', 'b', ..., 'z', 'aa', 'ab', ...)
		while (index >= 0) {
			id = String.fromCharCode(97 + (index % 26)) + id; // 97 is ASCII code for 'a'
			index = Math.floor(index / 26) - 1;
		}

		this.currentIndex += 1;

		return id;
	}
}

export default AlphabetIdGenerator;

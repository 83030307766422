import React, { useEffect, useState } from "react";
import VideoOption from "../VideoOption";
import { getFormData } from "../../utils/getFormData";
import axios from "axios";
import { getUser } from "../../utils/getUser";

const KeepOriginal = ({ keep_original }) => {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);
	const [done, setDone] = useState(false);

	useEffect(() => {
		if (done) {
			setTimeout(() => {
				setDone(false);
			}, 2000);
		}
	}, [done]);

	const originalFile = (e) => {
		e.preventDefault();
		setLoading(true);
		setError(false);
		setDone(false);
		const data = getFormData(e);
		const user = getUser();
		const formData = new FormData();
		formData.append("user_id", user);
		formData.append("keep_original", data[0].value);
		axios
			.post("/edit_original_link.php", formData)
			.then((response) => {
				if (response.data.status === "success") {
					setDone(true);
				} else {
					setError("An error occurred, Please try again!");
				}
			})
			.catch(() => {
				setError("An error occurred, Please try again!");
			})
			.finally(() => {
				setLoading(false);
			});
	};
	return (
		<form
			className="flex-1 flex flex-col justify-between gap-5 *:font-bold"
			onSubmit={originalFile}
		>
			<VideoOption name="Keep original Video" status={keep_original} />
			<p className="text-sm mb-5">
				* This option will let you keep original uploaded files so you
				can download them from video page again but will increase your
				storage usage
			</p>
			<input
				type="submit"
				className="button-lg p-3"
				value={loading ? "LOADING..." : "UPDATE"}
				disabled={loading}
			/>
			{error && <p className="text-pink">{error}</p>}
			{done && (
				<p className="text-green">
					Keep Original Option has been updated!
				</p>
			)}
		</form>
	);
};

export default KeepOriginal;

import React, { useEffect, useState } from "react";
import VideoOption from "../VideoOption";
import { getFormData } from "../../utils/getFormData";
import { getUser } from "../../utils/getUser";
import axios from "axios";

const PlayerOptions = ({ player_settings }) => {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);
	const [done, setDone] = useState(false);

	useEffect(() => {
		if (done) {
			setTimeout(() => {
				setDone(false);
			}, 2000);
		}
	}, [done]);

	const handleSubmit = (e) => {
		e.preventDefault();
		setLoading(true);
		setDone(false);
		setError(false);
		const data = getFormData(e);

		let formatedData = "";
		data.forEach((d) => {
			if (d.value) {
				formatedData += `${d.name},`;
			}
		});

		const user = getUser();
		const formData = new FormData();
		formData.append("user_id", user);
		formData.append("controls", formatedData.slice(0, -1));

		axios
			.post("/edit_player_options.php", formData)
			.then((response) => {
				if (response.data.status === "success") {
					setDone(true);
				} else {
					setError("An error occurred, Please try again!");
				}
			})
			.catch(() => {
				setError("An error occurred, Please try again!");
			})
			.finally(() => {
				setLoading(false);
			});
	};
	return (
		<div className="flex flex-col">
			<p className="font-bold text-xl mb-5">Player Options</p>
			<form
				className="p-5 bg-gray flex-1 flex flex-col gap-5 *:font-bold button-lg-no-active"
				onSubmit={handleSubmit}
			>
				<VideoOption
					name="10s Forward"
					status={player_settings.includes("fast-forward")}
					keyName={"fast-forward"}
					// description="10s Forward 10s Forward 10s Forward 10s Forward"
				/>
				<VideoOption
					name="10s Backward"
					status={player_settings.includes("rewind")}
					keyName={"rewind"}
				/>
				<VideoOption
					name="Air Play"
					status={player_settings.includes("airplay")}
					keyName={"airplay"}
				/>
				<VideoOption
					name="Big Play Button"
					status={player_settings.includes("play-large")}
					keyName={"play-large"}
				/>
				<VideoOption
					name="Captions"
					status={player_settings.includes("captions")}
					keyName={"captions"}
				/>
				<VideoOption
					name="Cast In Chrome"
					status={player_settings.includes("chromecast")}
					keyName={"chromecast"}
				/>
				<VideoOption
					name="Current Time"
					status={player_settings.includes("current-time")}
					keyName={"current-time"}
				/>
				<VideoOption
					name="Duration"
					status={player_settings.includes("duration")}
					keyName={"duration"}
				/>
				<VideoOption
					name="Full Screen"
					status={player_settings.includes("fullscreen")}
					keyName={"fullscreen"}
				/>
				<VideoOption
					name="Mute"
					status={player_settings.includes("mute")}
					keyName={"mute"}
				/>
				<VideoOption
					name="Play In Picture"
					status={player_settings.includes("pip")}
					keyName={"pip"}
				/>
				<VideoOption
					name="Play Button"
					status={player_settings.includes("play")}
					keyName={"play"}
				/>
				<VideoOption
					name="Progress"
					status={player_settings.includes("progress")}
					keyName={"progress"}
				/>
				<VideoOption
					name="Settings"
					status={player_settings.includes("settings")}
					keyName={"settings"}
				/>
				<VideoOption
					name="Volume"
					status={player_settings.includes("volume")}
					keyName={"volume"}
				/>
				<input
					type="submit"
					className="button-lg p-3"
					value={loading ? "LOADING..." : "UPDATE"}
					disabled={loading}
				/>
				{error && <p className="text-pink">{error}</p>}
				{done && (
					<p className="text-green">
						Player Options has been updated!
					</p>
				)}
			</form>
		</div>
	);
};

export default PlayerOptions;

import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const Feature = ({ name, check = true }) => {
	return (
		<div className="flex gap-5 items-center">
			<div className="h-8 w-8 aspect-square !bg-purplePrimary button-lg flex justify-center items-center">
				<FontAwesomeIcon
					icon={check ? faCheck : faTimes}
					className="*:text-black"
				/>
			</div>
			<p className="text-xl">{name}</p>
		</div>
	);
};

export default Feature;

import React from "react";
import Wolvy from "../components/Wolvy";
import { Link } from "react-router-dom";

const NotFound = () => {
	return (
		<div className="flex items-center h-screen w-full bg-purpleSecondary">
			<div className="w-full flex flex-col gap-5">
				<p className=" text-3xl text-center">Error 404! Not Found!</p>
				<Wolvy />
				<div className="mx-auto">
					<Link className=" button-lg px-10 py-5" to="/">
						Return to Home Page
					</Link>
				</div>
			</div>
		</div>
	);
};

export default NotFound;

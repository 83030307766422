import React from "react";
import { Link } from "react-router-dom";

const WhyWolvy = ({ titles, descriptions }) => {
	return (
		<div className="flex gap-10 justify-between flex-wrap md:flex-row flex-col *:flex-1">
			<div className="shadow-[5px_5px_#000] flex flex-col justify-between p-5 pb-7 bg-purplePrimary">
				<div className="flex gap-3 items-center">
					<img
						src="/images/why-1.png"
						alt="Security"
						className="w-16"
					/>
					<h1 className="text-purpleSecondary  text-2xl md:text-4xl">
						{titles[0]}
					</h1>
				</div>
				<p className="my-7 *:text-purpleSecondary *:mb-3">
					{descriptions[0].map((d, i) => (
						<p key={i}>{d}</p>
					))}
				</p>
				<div className="flex justify-center">
					<Link
						to="/register"
						className="button-lg !bg-transparent !border-2 px-5 py-3 font-bold text-sm text-purpleSecondary"
					>
						EXPLORE NOW
					</Link>
				</div>
			</div>
			<div className="shadow-[5px_5px_#000] flex flex-col justify-between p-5 pb-7 bg-yellow">
				<div className="flex gap-3 items-center">
					<img
						src="/images/why-1.png"
						alt="Security"
						className="w-16"
					/>
					<h1 className="text-purpleSecondary  text-2xl md:text-4xl">
						{titles[1]}
					</h1>
				</div>
				<p className="my-7 *:text-purpleSecondary *:mb-3">
					{descriptions[1].map((d, i) => (
						<p key={i}>{d}</p>
					))}
				</p>
				<div className="flex justify-center">
					<Link
						to="/register"
						className="button-lg !bg-transparent !border-2 px-5 py-3 font-bold text-sm text-purpleSecondary"
					>
						EXPLORE NOW
					</Link>
				</div>
			</div>
			<div className="shadow-[5px_5px_#000] flex flex-col justify-between p-5 pb-7 bg-pink">
				<div className="flex gap-3 items-center">
					<img
						src="/images/why-1.png"
						alt="Security"
						className="w-16"
					/>
					<h1 className="text-purpleSecondary  text-2xl md:text-4xl">
						{titles[2]}
					</h1>
				</div>
				<p className="my-7 *:text-purpleSecondary *:mb-3">
					{descriptions[2].map((d, i) => (
						<p key={i}>{d}</p>
					))}
				</p>
				<div className="flex justify-center">
					<Link
						to="/register"
						className="button-lg !bg-transparent !border-2 px-5 py-3 font-bold text-sm text-purpleSecondary"
					>
						EXPLORE NOW
					</Link>
				</div>
			</div>
		</div>
	);
};

export default WhyWolvy;

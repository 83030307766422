import React from "react";
import TimeIntervalPicker from "../TimePicker";

const Chapters = ({ data, setData, videoID }) => {
	return (
		<div className="flex flex-col">
			<p className="font-bold text-xl mb-5">Chapters</p>
			<div className="p-5 bg-gray flex-1 flex flex-col gap-5 *:font-bold button-lg-no-active">
				<TimeIntervalPicker
					chaptersData={data.chapters}
					videoID={videoID}
					duration={Number(data.duration)}
					setData={setData}
				/>
			</div>
		</div>
	);
};

export default Chapters;

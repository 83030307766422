import React, { useEffect, useState } from "react";
import { checkDomain } from "../../utils/checkDomains";
import { getUser } from "../../utils/getUser";
import { compareArrays } from "../../utils/compareArrays";
import axios from "axios";
import VideoList from "../VideoList";

const BlockedDomians = ({ blocked_domains, setData }) => {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);
	const [done, setDone] = useState(false);

	useEffect(() => {
		if (done) {
			setTimeout(() => {
				setDone(false);
			}, 2000);
		}
	}, [done]);

	const handleBlockedDomains = async (data) => {
		setLoading(true);
		setDone(false);
		setError(false);

		try {
			const user = getUser();
			const refrence = JSON.parse(blocked_domains);
			const { removed, added } = compareArrays(refrence, data);

			const removeEndpoint = "/remove_block_domain.php";
			const addEndpoint = "/add_block_domain.php";

			let response;
			for (const domain of removed) {
				const formData = new FormData();
				formData.append("user_id", user);
				formData.append("domain", domain);
				response = await axios.post(removeEndpoint, formData);
			}

			for (const domain of added) {
				const formData = new FormData();
				formData.append("user_id", user);
				formData.append("domain", domain.label);
				response = await axios.post(addEndpoint, formData);
			}
			if (response) {
				setData((prevData) => ({
					...prevData,
					blocked_domains: JSON.stringify(
						response.data?.blocked_domains || []
					),
				}));
				if (response.data.status === "success") {
					setDone(true);
				} else {
					setError("An error occurred, Please try again!");
				}
				return;
			}
			setDone(true);
		} catch (error) {
			setError("An error occurred, Please try again!");
		} finally {
			setLoading(false);
		}
	};
	return (
		<div className="flex flex-col">
			<p className="font-bold text-xl mb-5">Blocked Domains</p>
			<div className="p-5 bg-gray flex-1 flex flex-col gap-5 *:font-bold button-lg-no-active">
				<VideoList
					inputs={[]}
					label="domain"
					placeholder="Example: *.exmaple.com"
					domiansDefaultValues={JSON.parse(blocked_domains)}
					checkLabel={checkDomain}
					onSubmit={handleBlockedDomains}
					submitLoading={loading}
					checkDuplicated={true}
				/>
				{error && <p className="text-pink">{error}</p>}
				{done && (
					<p className="text-green">
						Blocked Domains has been updated!
					</p>
				)}
			</div>
		</div>
	);
};

export default BlockedDomians;

import React from "react";

const ContextVideoMenu = ({
	menuPosition,
	selections,
	setSelections,
	setMoving,
	fileActive,
	setDeleteVideo,
	setVideoDeleted,
}) => {
	const handleMove = () => {
		if (selections.length) {
			setMoving(selections);
		} else if (fileActive) {
			setMoving(fileActive);
		}
	};
	const handleDelete = () => {
		setVideoDeleted({
			videoID: selections.map((s) => s.id),
			videoName: selections.map((s) => s.title),
		});
		setDeleteVideo(true);
	};
	return (
		<div
			className="absolute min-w-72 flex flex-col cursor-pointer z-50 border-[1px] border-black"
			style={{
				top: `${menuPosition.y}px`,
				left: `${menuPosition.x}px`,
			}}
		>
			<div
				className={`px-5 py-3 border-b-[1px] border-b-black ${
					selections.length || fileActive
						? "bg-purplePrimary"
						: "bg-purplePrimaryDisabled !cursor-not-allowed *:!cursor-not-allowed"
				}`}
				onClick={handleMove}
			>
				Move
			</div>
			<div
				className={`px-5 py-3 border-b-[1px] border-b-black ${
					selections.length || fileActive
						? "bg-purplePrimary"
						: "bg-purplePrimaryDisabled !cursor-not-allowed *:!cursor-not-allowed"
				}`}
				onClick={handleDelete}
			>
				Delete
			</div>
			<div
				className={`px-5 py-3 border-b-[1px] border-b-black ${
					selections.length
						? "bg-purplePrimary"
						: "bg-purplePrimaryDisabled !cursor-not-allowed *:!cursor-not-allowed"
				}`}
				onClick={() => selections.length && setSelections([])}
			>
				Remove Selections
			</div>
		</div>
	);
};

export default ContextVideoMenu;

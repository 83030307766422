import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { decode } from "../utils/crypto";

const CheckUser = () => {
	const [user, setUser] = useState(null); // Default state to null for clarity
	const navigate = useNavigate();

	useEffect(() => {
		const part1 = sessionStorage.getItem("session");
		const part2 = sessionStorage.getItem("vid");
		const decoded = decode(part1, part2);
		if (!isNaN(decoded)) {
			setUser(decoded);
		} else {
			setUser("No User");
		}
	}, []);

	useEffect(() => {
		if (user && user !== "No User") {
			navigate("/dashboard");
		}
	}, [user, navigate]);

	if (user === null) {
		// Optional: Show a loading state while determining the user
		return <div>Loading...</div>;
	}

	if (user === "No User") {
		return <Outlet />;
	}

	return null; // If user exists, navigation will occur in the effect
};

export default CheckUser;

import axios from "axios";
import { getUser } from "./getUser";

export const dashboardData = async () => {
	const user = getUser();
	if (!user || user === "Error") return [];
	const endpoints = [
		{
			path: "/analytics_overview.php",
			data: () => {
				const formData = new FormData();
				formData.append("user_id", user);
				formData.append("time_frame", "year");
				return formData;
			},
		},
		{
			path: "/get_recent_videos.php",
			data: () => {
				const formData = new FormData();
				formData.append("user_id", user);
				formData.append("page", 1);
				formData.append("limit", 5);
				return formData;
			},
		},
	];
	const data = await axios.all(
		endpoints.map((endpoint) => axios.post(endpoint.path, endpoint.data()))
	);
	const returnData = [];
	data.forEach((d) => {
		returnData.push(d.data);
	});
	return returnData;
};

export const videosData = async () => {
	const user = getUser();
	if (!user || user === "Error") return [];
	if (user) {
		const endpoints = [
			{
				path: "/list_folders.php",
				data: () => {
					const formData = new FormData();
					formData.append("user_id", user);
					formData.append("page", 1);
					formData.append("limit", 8);
					return formData;
				},
			},
			{
				path: "/list_videos.php",
				data: () => {
					const formData = new FormData();
					formData.append("user_id", user);
					formData.append("page", 1);
					formData.append("limit", 8);
					return formData;
				},
			},
			{
				path: "/get_plan.php",
				data: () => {
					const formData = new FormData();
					formData.append("user_id", user);
					return formData;
				},
			},
		];
		const data = await axios.all(
			endpoints.map((endpoint) =>
				axios.post(endpoint.path, endpoint.data())
			)
		);
		const returnData = [];
		data.forEach((d) => {
			returnData.push(d.data);
		});
		return returnData;
	}
};

export const videosListData = async () => {
	const user = getUser();
	if (!user || user === "Error") return [];
	if (user) {
		const endpoints = [
			{
				path: "/list_videos.php",
				data: () => {
					const formData = new FormData();
					formData.append("user_id", user);
					return formData;
				},
			},
		];
		const data = await axios.all(
			endpoints.map((endpoint) =>
				axios.post(endpoint.path, endpoint.data())
			)
		);
		const returnData = [];
		data.forEach((d) => {
			returnData.push(d.data);
		});
		return returnData;
	}
};

export const fileData = async ({ params }) => {
	const { id } = params;
	const user = getUser();
	if (!user || user === "Error") return [];
	const endpoints = [
		{
			path: "/get_video.php",
			method: "POST",
			data: () => {
				const formData = new FormData();
				formData.append("user_id", user);
				formData.append("video_id", id);
				return formData;
			},
		},
	];
	const data = await axios.all(
		endpoints.map((endpoint) =>
			axios(endpoint.path, {
				method: endpoint.method,
				data: endpoint.data(),
			})
		)
	);
	return [data[0].data, id];
};

export const accountData = async () => {
	const user = getUser();
	if (!user || user === "Error") return [];
	const endpoints = [
		{
			path: "/get_user.php",
			method: "POST",
			data: () => {
				const formData = new FormData();
				formData.append("user_id", user);
				return formData;
			},
		},
		// {
		// 	path: "/get_languages.json",
		// 	method: "GET",
		// 	data: () => [],
		// },
	];
	const data = await axios.all(
		endpoints.map((endpoint) =>
			axios(endpoint.path, {
				method: endpoint.method,
				data: endpoint.data(),
			})
		)
	);
	return [data];
};

export const avatarData = async () => {
	const user = getUser();
	if (!user || user === "Error") return [];
	const endpoints = {
		path: "/get_user.php",
		method: "POST",
		data: () => {
			const formData = new FormData();
			formData.append("user_id", user);
			return formData;
		},
	};
	const data = await axios(endpoints.path, {
		method: endpoints.method,
		data: endpoints.data(),
	});
	return data.data;
};

export const paymentData = async () => {
	const user = getUser();
	if (!user || user === "Error") return [];
	const endpoints = [
		{
			path: "/get_payments.php",
			method: "POST",
			data: () => {
				const formData = new FormData();
				formData.append("user_id", user);
				formData.append("limit", 10);
				return formData;
			},
		},
		{
			path: "/get_plan.php",
			method: "POST",
			data: () => {
				const formData = new FormData();
				formData.append("user_id", user);
				return formData;
			},
		},
	];
	const data = await axios.all(
		endpoints.map((endpoint) =>
			axios(endpoint.path, {
				method: endpoint.method,
				data: endpoint.data(),
			})
		)
	);
	return data;
};

export const analyticsData = async () => {
	const user = getUser();
	if (!user || user === "Error") return [];
	const endpoints = [
		{
			path: "/analytics_overview.php",
			data: () => {
				const formData = new FormData();
				formData.append("user_id", user);
				formData.append("time_frame", "year");
				return formData;
			},
		},
		{
			path: "/get_daily_analytics.php",
			data: () => {
				const formData = new FormData();
				formData.append("user_id", user);
				formData.append("limit", 5);
				return formData;
			},
		},
		{
			path: "/get_analytics_by_country.php",
			data: () => {
				const formData = new FormData();
				formData.append("user_id", user);
				formData.append("aggregation", "year");
				return formData;
			},
		},
	];
	const data = await axios.all(
		endpoints.map((endpoint) => axios.post(endpoint.path, endpoint.data()))
	);
	const returnData = [];
	data.forEach((d) => {
		returnData.push(d.data);
	});
	return returnData;
};

export const folderVideos = async ({ params }) => {
	const { id } = params;
	if (!id) return [];
	const user = getUser();
	if (!user || user === "Error") return [];
	if (user) {
		const endpoints = [
			{
				path: "/list_folders.php",
				data: () => {
					const formData = new FormData();
					formData.append("user_id", user);
					return formData;
				},
			},
			{
				path: "/list_videos.php",
				data: () => {
					const formData = new FormData();
					formData.append("user_id", user);
					formData.append("folder_id", id);
					// formData.append("limit", 8);
					// formData.append("page", 1);
					return formData;
				},
			},
		];
		const data = await axios.all(
			endpoints.map((endpoint) =>
				axios.post(endpoint.path, endpoint.data())
			)
		);
		const returnData = [];
		data.forEach((d, i) => {
			i == 0
				? returnData.push(
						d.data.folders.filter((f) => f.id === id)[0]?.name
				  )
				: returnData.push(d.data);
		});
		return returnData;
	}
};

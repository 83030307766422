import React, { useState, createContext } from "react";
import useDropzone from "../utils/use-dropzone.tsx";

export const Dragging = createContext();

export const DraggingProvider = ({ children }) => {
	const isDragging = useDropzone();
	const [dragging, setDragging] = useState(false);
	const handleDragOver = (e) => {
		e.preventDefault();
		if (isDragging) {
			setDragging(true);
		}
	};

	const handleDragLeave = (e) => {
		if (e.clientX === 0 && e.clientY === 0) {
			setDragging(false);
		}
	};

	const handleDrop = (e) => {
		setDragging(false);
	};

	return (
		<Dragging.Provider value={{ dragging, setDragging }}>
			<div
				onDragOver={handleDragOver}
				onDrop={handleDrop}
				onDragLeave={handleDragLeave}
			>
				{children}
			</div>
		</Dragging.Provider>
	);
};

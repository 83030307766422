import React from "react";
import { useNavigate } from "react-router-dom";

const File = ({
	wolvy_id,
	title,
	status,
	poster,
	animated_poster,
	selections,
	setSelections,
	className,
	setFileDragged = false,
	skipStatus = false,
}) => {
	const navigate = useNavigate();
	const formatTitle = (title) => {
		if (title.length > 20) {
			const text = title.split(".");
			if (text[1]) {
				text.pop();
			}
			return `${text.join("").slice(0, 20)}...`;
		} else {
			return title;
		}
	};

	const dragVideo = (e) => {
		setSelections((data) => {
			if (data.filter((d) => d.id === wolvy_id).length) {
				e.dataTransfer.setData("selection", JSON.stringify(data));
				return data;
			} else {
				const newData = [...data, { id: wolvy_id, title: title }];
				e.dataTransfer.setData("selection", JSON.stringify(newData));
				return newData;
			}
		});
		setFileDragged && setFileDragged(true);
	};

	return (
		<div
			onClick={() => {
				if (setSelections) {
					setSelections((data) => {
						if (data.filter((d) => d.id === wolvy_id).length) {
							return data.filter((d) => d !== wolvy_id);
						} else {
							return [...data, { id: wolvy_id, title: title }];
						}
					});
				} else {
					navigate(`/file/${wolvy_id}`);
				}
			}}
			draggable={selections ? true : false}
			onDragStart={selections ? dragVideo : () => {}}
			onDragEnd={() => setFileDragged && setFileDragged(false)}
			onDoubleClick={() =>
				status === "3" && navigate(`/file/${wolvy_id}`)
			}
			className={`mx-5 select-none flex flex-col justify-between button min-h-52 carousel-item ${
				selections?.filter((d) => d.id === wolvy_id).length &&
				"outline-2 outline-double outline-white"
			} ${className || ""}`}
			aria-description={wolvy_id}
			aria-label={title}
		>
			<div className="bg-purplePrimary border-b-2 p-1 px-2">
				<p title={title} className="text-center">
					{formatTitle(title)}
				</p>
			</div>
			<div className="bg-[#d4bef2] px-1 py-3 relative">
				{!skipStatus ? (
					Number(status) !== 3 ? (
						Number(status) === 5 ? (
							<div className="absolute top-0 left-0 *:text-black *:font-bold bg-white/50 flex justify-center items-center h-full w-full">
								<p>Error</p>
							</div>
						) : (
							<img
								className="absolute top-0 left-0 h-full w-full object-cover object-center"
								src="/images/processing.gif"
								alt="Processing"
							/>
						)
					) : (
						""
					)
				) : (
					""
				)}
				<div className="border-2">
					{poster ? (
						<img
							onMouseEnter={(e) =>
								(e.target.src = animated_poster)
							}
							onMouseLeave={(e) => (e.target.src = poster)}
							src={poster}
							alt={title}
							className="object-cover h-52 w-full"
							draggable={false}
						/>
					) : (
						<div className="h-52 w-full bg-white"></div>
					)}
				</div>
			</div>
		</div>
	);
};

export default File;

import axios from "axios";
import React, { useState } from "react";

const ForgetPassword = () => {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);
	const [success, setSuccess] = useState(false);
	const handleForget = (e) => {
		e.preventDefault();
		setLoading(true);
		setError(false);
		setSuccess(false);
		const email = e.target.email.value;
		if (!email) {
			setError("Email is required.");
			return;
		}
		const modifiedEmail =
			email.split("@")[0].split(".").join("") + "@" + email.split("@")[1];
		const formData = new FormData();
		formData.append("email", modifiedEmail);
		axios
			.post("/forget_password.php", formData)
			.then((response) => {
				if (response.data.status === "success") {
					setSuccess(true);
				} else {
					setError(
						"An error occurred, Please check you email and try again!"
					);
				}
			})
			.catch(() => {
				setError("An error occurred, Please try again!");
			})
			.finally(() => {
				setLoading(false);
			});
	};
	return (
		<div className="flex justify-center items-center bg-purpleSecondary h-screen w-full">
			<div className="p-10 bg-[rgb(61,61,61)] flex flex-col gap-5 border-2 border-black shadow-[1px_1px_0_0_#000]">
				<p className="text-5xl ">Forget Password</p>
				<form
					onSubmit={handleForget}
					className="flex flex-col md:max-w-[500px] gap-5 min-w-80 [&>div]:flex [&>div]:flex-col"
				>
					<div>
						<label className="font-semibold" htmlFor="email">
							Email
						</label>
						<input
							type="email"
							className="p-2 bg-transparent border-[1px] border-[rgb(50,50,50)] text-sm"
							id="email"
							name="email"
							placeholder="Enter your email"
							required
						/>
					</div>
					{error && (
						<p className="text-pink text-sm font-bold">{error}</p>
					)}
					{success && (
						<p className="text-green text-center text-sm font-bold">
							Password reset link sent to your email.
						</p>
					)}
					<input
						type="submit"
						className="p-2 button"
						disabled={loading}
						value={loading ? "Loading..." : "GET RESET PASSWORD"}
					/>
				</form>
			</div>
		</div>
	);
};

export default ForgetPassword;

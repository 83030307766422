import {
	faChevronLeft,
	faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const Pagination = ({ pages, current, update }) => {
	if (pages > 1) {
		return (
			<div className="flex gap-5 items-center justify-center flex-wrap">
				<div
					className={`h-7 w-7 button-lg flex justify-center items-center mt-3 ${
						current === 1 &&
						"!bg-purplePrimary/10 !cursor-not-allowed"
					}`}
					onClick={() => {
						current !== 1 && update(current - 1);
					}}
				>
					<FontAwesomeIcon
						className={`${
							current === 1 && "*:!cursor-not-allowed"
						}`}
						icon={faChevronLeft}
					/>
				</div>
				{Array.from({ length: pages }, (_, i) => i + 1).map((p) => (
					<div
						key={p}
						className={`h-7 w-7 ${
							current === p && "!bg-purpleSecondary"
						} button-lg flex justify-center items-center mt-3`}
						onClick={() => {
							current !== p && update(p);
						}}
					>
						{p}
					</div>
				))}
				<div
					className={`h-7 w-7 button-lg flex justify-center items-center mt-3 
					${current === pages && "!bg-purplePrimary/10 !cursor-not-allowed"}`}
					onClick={() => {
						current !== pages && update(current + 1);
					}}
				>
					<FontAwesomeIcon
						className={`${
							current === pages && "*:!cursor-not-allowed"
						}`}
						icon={faChevronRight}
					/>
				</div>
			</div>
		);
	} else {
		return <></>;
	}
};

export default Pagination;

import React, { useState, createContext } from "react";

export const MenuBackground = createContext();

export const MenuBackgroundProvider = ({ children }) => {
	const [menuBackground, setMenuBackground] = useState(0);

	return (
		<MenuBackground.Provider value={{ menuBackground, setMenuBackground }}>
			{children}
		</MenuBackground.Provider>
	);
};

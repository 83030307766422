import React from "react";
import "./index.css";
import App from "./App";
import { createHashRouter, RouterProvider } from "react-router-dom";
import { createRoot } from "react-dom/client";
import Login from "./pages/Login";
import Register from "./pages/Register";
import AccountLayout from "./AccountLayout";
import Dashboard from "./pages/Dashboard";
import AccountAndPayments from "./pages/AccountAndPayments";
import MainLayout from "./MainLayout";
import { MenuBackgroundProvider } from "./context/MenuContext";
import ContactUs from "./pages/ContactUs";
import Pricing from "./pages/Pricing";
import Videos from "./pages/Videos";
import Folder from "./pages/Folder";
import Video from "./pages/Video";
import Analytics from "./pages/Analytics";
import { DraggingProvider } from "./context/DraggingContext";
import { DropzoneProvider } from "./utils/use-dropzone.tsx";
import MyAccount from "./pages/MyAccount.js";
import SecurityComparison from "./pages/SecurityComparison.js";
import About from "./pages/About.js";
import CheckUser from "./middlewares/CheckUser";
import axios from "axios";
import Auth from "./middlewares/Auth.js";
import VideosList from "./pages/VideosList.js";
import ForgetPassword from "./pages/ForgetPassword.js";
import {
	accountData,
	analyticsData,
	avatarData,
	dashboardData,
	fileData,
	folderVideos,
	paymentData,
	videosData,
	videosListData,
} from "./utils/routeLoaders.js";
import ResetPassword from "./pages/ResetPassword.js";
import NotFound from "./pages/NotFound.js";

axios.defaults.baseURL = "https://wolvy.net/backend";

const router = createHashRouter([
	{
		path: "/",
		element: <CheckUser />,
		children: [
			{ path: "login", element: <Login /> },
			{ path: "register", element: <Register /> },
			{ path: "forget", element: <ForgetPassword /> },
			{ path: "reset_password", element: <ResetPassword /> },
		],
	},
	{
		path: "/",
		element: <MainLayout />,
		children: [
			{ index: true, element: <App /> },
			{ path: "contact-us", element: <ContactUs /> },
			{ path: "pricing", element: <Pricing /> },
			{ path: "security-comparison", element: <SecurityComparison /> },
			{ path: "about", element: <About /> },
		],
	},
	{
		path: "/",
		element: <Auth />,
		children: [
			{
				path: "/",
				element: <AccountLayout />,
				loader: avatarData,
				children: [
					{
						path: "dashboard",
						element: <Dashboard />,
						loader: dashboardData,
					},
					{
						path: "account-and-payments",
						element: <AccountAndPayments />,
						loader: paymentData,
					},
					{
						path: "folders",
						element: <Videos />,
						loader: videosData,
					},
					{
						path: "folders/:id",
						element: <Folder />,
						loader: folderVideos,
					},
					{
						path: "videos",
						element: <VideosList />,
						loader: videosListData,
					},
					{ path: "file/:id", element: <Video />, loader: fileData },
					{
						path: "account",
						element: <MyAccount />,
						loader: accountData,
					},
					{
						path: "analytics",
						element: <Analytics />,
						loader: analyticsData,
					},
				],
			},
		],
	},
	{
		path: "*",
		element: <NotFound />,
	},
]);

createRoot(document.getElementById("root")).render(
	<DropzoneProvider>
		<DraggingProvider>
			<MenuBackgroundProvider>
				<RouterProvider router={router} />
			</MenuBackgroundProvider>
		</DraggingProvider>
	</DropzoneProvider>
);

import React, { useEffect, useState } from "react";
import VideoList from "../VideoList";
import { getUser } from "../../utils/getUser";
import { compareArrays } from "../../utils/compareArrays";
import { formatHighlightsData } from "../../utils/formatHighlights";
import axios from "axios";

const Highlights = ({ data, videoID }) => {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);
	const [done, setDone] = useState(false);

	useEffect(() => {
		if (done) {
			setTimeout(() => {
				setDone(false);
			}, 2000);
		}
	}, [done]);

	const handleHighlights = async (e) => {
		setLoading(true);
		setError(false);
		setDone(false);
		try {
			const user = getUser();
			const { removed, added } = compareArrays(
				formatHighlightsData(data.moments || []),
				e
			);

			const removeEndpoint = "/delete_highlight.php";
			const addEndpoint = "/add_highlight.php";

			let errros = false;

			for (const chapter of removed) {
				const formData = new FormData();
				formData.append("user_id", user);
				formData.append("video_id", videoID);
				formData.append("timestamp", chapter.inputs);
				const response = await axios.post(removeEndpoint, formData);
				if (response.data.status === "error") {
					setError("An error occurred, Please try again!");
					errros = true;
				}
			}

			for (const chapter of added) {
				const formData = new FormData();
				formData.append("user_id", user);
				formData.append("video_id", videoID);
				formData.append("label", chapter.label);
				formData.append("timestamp", chapter.inputs);
				const response = await axios.post(addEndpoint, formData);
				if (response.data.status === "error") {
					setError("An error occurred, Please try again!");
					errros = true;
				}
			}
			if (!errros) {
				setDone(true);
			}
		} catch (error) {
			setError("An error occurred, Please try again!");
		} finally {
			setLoading(false);
		}
	};
	return (
		<div className="flex flex-col">
			<p className="font-bold text-xl mb-5">Highlights</p>
			<div className="p-5 bg-gray flex-1 flex flex-col gap-5 *:font-bold button-lg-no-active">
				<VideoList
					inputs={false}
					label="Label"
					placeholder="Label"
					editable={false}
					defaultValues={data.moments}
					onSubmit={handleHighlights}
					submitLoading={loading}
					duration={data.duration}
				/>
				{error && <p className="text-pink">{error}</p>}
				{done && (
					<p className="text-green">Highlights have been updated!</p>
				)}
			</div>
		</div>
	);
};

export default Highlights;

import React, { useEffect, useRef, useState } from "react";
import { useLoaderData, useSearchParams } from "react-router-dom";
import FoldersSection from "../components/Videos/FoldersSection";
import VideosSection from "../components/Videos/VideosSection";

const getPlan = (result) => {
	if (result[2].status === "error") {
		return null;
	} else {
		return result[2].data;
	}
};

const Videos = () => {
	const uploader = useRef(null);
	const result = useLoaderData();
	const plan = getPlan(result);

	const [activeMenu, setActiveMenu] = useState(null);
	const toggleFolderMenu = () => {
		setActiveMenu((prev) => (prev === "folder" ? null : "folder"));
	};

	const toggleVideoMenu = () => {
		setActiveMenu((prev) => (prev === "video" ? null : "video"));
	};

	const isFolderMenuVisible = activeMenu === "folder";
	const isVideoMenuVisible = activeMenu === "video";

	const [moving, setMoving] = useState([]);

	const [fileDragged, setFileDragged] = useState(false);

	const [searchParams, setSearchParams] = useSearchParams();
	const status = searchParams.get("status");

	const [uplaodDone, setUplaodDone] = useState(false);

	return (
		<div
			className="h-full bg-purpleSecondary"
			onClick={() => setActiveMenu(null)}
		>
			<div className="p-7 border-b-[1px] border-b-gray">
				<p className="text-4xl ">Videos</p>
			</div>
			{status === "done" ? (
				<p className="text-green p-5">Videos deleted successfully</p>
			) : status === "error" ? (
				<p className="text-pink p-5">
					An error occurred, Please try again!
				</p>
			) : (
				""
			)}
			{uplaodDone ? (
				<p className="text-green p-5">
					Videos uploaded! You don't need to stay in the page no
					longer while processing the videos
				</p>
			) : (
				""
			)}
			<div className="p-7">
				<FoldersSection
					uploader={uploader}
					result={result}
					isMenuVisible={isFolderMenuVisible}
					setMenuVisible={toggleFolderMenu}
					fileDragged={fileDragged}
					moving={moving}
					plan={plan}
					setUplaodDone={setUplaodDone}
				/>
				<VideosSection
					result={result}
					uploader={uploader}
					isMenuVisible={isVideoMenuVisible}
					setMenuVisible={toggleVideoMenu}
					setMoving={setMoving}
					setFileDragged={setFileDragged}
					plan={plan}
					setUplaodDone={setUplaodDone}
				/>
			</div>
		</div>
	);
};

export default Videos;

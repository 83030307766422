import axios from "axios";
import React, { useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

const ResetPassword = () => {
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();
	const key = searchParams.get("key");

	if (!key) {
		navigate("/forget");
	}

	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);
	const [success, setSuccess] = useState(false);

	const validatePassword = (password, setError) => {
		setError("");
		if (password === "") {
			setError("");
			return 0;
		}
		if (password.length < 8) {
			setError("Password must be at least 8 characters long.");
			return 0;
		}
		if (!/[a-z]/.test(password)) {
			setError("Password must contain at least one lowercase letter.");
			return 0;
		}
		if (!/[A-Z]/.test(password)) {
			setError("Password must contain at least one uppercase letter.");
			return 0;
		}
		if (!/[\W_]/.test(password)) {
			setError("Password must contain at least one symbol.");
			return 0;
		}
		return 1;
	};
	const handleReset = (e) => {
		e.preventDefault();
		setLoading(true);
		setError(false);
		setSuccess(false);
		const password = e.target.password.value;
		if (!password) {
			setError("Password is required.");
			return;
		}
		const validated = validatePassword(password, setError);
		if (!validated) return;
		const formData = new FormData();
		formData.append("token", key);
		formData.append("new_password", password);
		axios
			.post("/reset_password.php", formData)
			.then((response) => {
				if (response.data.status === "success") {
					setSuccess(true);
					setTimeout(() => {
						navigate("/login");
					}, 2000);
				} else {
					setError(
						"Invalid token, try to send a reset password reqeust again."
					);
				}
			})
			.catch(() => {
				setError("An error occurred, Please try again!");
			})
			.finally(() => {
				setLoading(false);
			});
	};
	return (
		<div className="flex justify-center items-center bg-purpleSecondary h-screen w-full">
			<div className="p-10 bg-[rgb(61,61,61)] flex flex-col gap-5 border-2 border-black shadow-[1px_1px_0_0_#000]">
				<p className="text-5xl ">Reset Password</p>
				<form
					onSubmit={handleReset}
					className="flex flex-col md:max-w-[500px] gap-5 min-w-80 [&>div]:flex [&>div]:flex-col"
				>
					<div>
						<label className="font-semibold" htmlFor="password">
							Password
						</label>
						<input
							type="password"
							className="p-2 bg-transparent border-[1px] border-[rgb(50,50,50)] text-sm"
							id="password"
							name="password"
							placeholder="Enter your password"
							required
						/>
					</div>
					{error && (
						<p className="text-pink text-sm font-bold">{error}</p>
					)}
					{success && (
						<p className="text-green text-center text-sm font-bold">
							Password has been reset!
						</p>
					)}
					<input
						type="submit"
						className="p-2 button"
						disabled={loading}
						value={loading ? "Loading..." : "RESET PASSWORD"}
					/>
				</form>
			</div>
		</div>
	);
};

export default ResetPassword;

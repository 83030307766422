export const formatTime = (seconds) => {
	const hours = Math.floor(seconds / 3600);
	const minutes = Math.floor((seconds % 3600) / 60);
	const secs = Math.floor(seconds % 60);

	if (hours > 0) {
		return `${hours.toString().padStart(2, "0")}:${minutes
			.toString()
			.padStart(2, "0")}:${secs.toString().padStart(2, "0")}`;
	}
	return `${minutes.toString().padStart(2, "0")}:${secs
		.toString()
		.padStart(2, "0")}`;
};

export const formatChaptersData = (data) => {
	if (!data) return [];
	const modifiedData = [];
	data.forEach((d, i) => {
		modifiedData.push({
			id: i,
			label: d.title,
			intervals: {
				start: d.start,
				end: d.end,
			},
		});
	});
	return modifiedData;
};

export const formatIntervalData = (data) => {
	if (!data) return [];
	const modifiedData = [];
	data.forEach((d, i) => {
		modifiedData.push({
			start: d.start,
			end: d.end,
		});
	});
	return modifiedData;
};

export const getAvailableIntervals = (intervals, videoDuration) => {
	const sortedIntervals = [...intervals].sort((a, b) => a.start - b.start);
	const available = [];
	let lastEnd = 0;

	sortedIntervals.forEach((interval) => {
		if (interval.start > lastEnd) {
			available.push({ start: lastEnd, end: interval.start });
		}
		lastEnd = interval.end;
	});

	if (lastEnd < videoDuration) {
		available.push({ start: lastEnd, end: videoDuration });
	}

	return available;
};

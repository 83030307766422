import React from "react";
import { formatBytes } from "../../utils/formatBytes";

const VideoAnalytics = ({ data }) => {
	return (
		<div className="flex flex-col">
			<p className="font-bold text-xl mb-5">Video Analytics</p>
			<div className="p-5 bg-gray flex-1 button-lg-no-active flex flex-col gap-5 *:font-bold">
				<p>Video Size: {formatBytes(data.size)}</p>
				<p>Video Views: {data.views}</p>
				<p>Engagment Score: {data.engagement_score}%</p>
			</div>
		</div>
	);
};

export default VideoAnalytics;

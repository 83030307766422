import React from "react";

const Progress = ({ width, background, height }) => {
	return (
		<div
			className="w-full h-4 overflow-hidden bg-purpleSecondary shadow-[inset_0_-2px_15px_#4a2576]"
			style={{ height: height != null && `${height}px` }}
		>
			<div
				style={{ width: `${width}%`, background }}
				className="h-full"
			></div>
		</div>
	);
};

export default Progress;

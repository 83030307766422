import React from "react";

const TimeInput = ({ value, onChange, max, label }) => {
	const hours = Math.floor(value / 3600);
	const minutes = Math.floor((value % 3600) / 60);
	const seconds = value % 60;
	const maxHours = Math.floor(max / 3600);
	const showHours = max >= 3600;

	const handleHoursChange = (e) => {
		const newHours = Math.min(
			Math.max(0, parseInt(e.target.value) || 0),
			maxHours
		);
		onChange(newHours * 3600 + minutes * 60 + seconds);
	};

	const handleMinutesChange = (e) => {
		const newMinutes = Math.min(
			Math.max(0, parseInt(e.target.value) || 0),
			59
		);
		onChange(hours * 3600 + newMinutes * 60 + seconds);
	};

	const handleSecondsChange = (e) => {
		const newSeconds = Math.min(
			Math.max(0, parseInt(e.target.value) || 0),
			59
		);
		onChange(hours * 3600 + minutes * 60 + newSeconds);
	};

	return (
		<div className="flex items-center space-x-1 my-2">
			{showHours && (
				<>
					<input
						type="number"
						min="0"
						max={maxHours}
						value={hours}
						onChange={handleHoursChange}
						className="w-16 bg-grayLight  px-2 py-1 focus:outline-none"
						aria-label={`${label} hours`}
					/>
					<span className="">:</span>
				</>
			)}
			<input
				type="number"
				min="0"
				max="59"
				value={minutes}
				onChange={handleMinutesChange}
				className="w-16 bg-grayLight  px-2 py-1 focus:outline-none"
				aria-label={`${label} minutes`}
			/>
			<span className="">:</span>
			<input
				type="number"
				min="0"
				max="59"
				value={seconds}
				onChange={handleSecondsChange}
				className="w-16 bg-grayLight  px-2 py-1 focus:outline-none focus:ring-2 focus:ring-blue-500"
				aria-label={`${label} seconds`}
			/>
		</div>
	);
};

export default TimeInput;

import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";

const FAQItem = ({ title, description }) => {
	const [opened, setopened] = useState(false);
	return (
		<div>
			<div
				className="m-3 cursor-pointer flex justify-between items-center"
				onClick={() => setopened(!opened)}
			>
				<p className="text-xl">{title}</p>
				<FontAwesomeIcon icon={opened ? faChevronUp : faChevronDown} />
			</div>
			{opened && <p className="m-10">{description}</p>}
		</div>
	);
};

export default FAQItem;

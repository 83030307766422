export const formatHighlightsData = (data) => {
	const modifiedData = [];
	data.forEach((v, i) => {
		modifiedData.push({
			id: i,
			inputs: v.timestamp,
			label: v.label,
		});
	});
	return modifiedData;
};

import React, { useState } from "react";
import Status from "../components/Status";
import Plans from "../components/Plans";
import { useLoaderData } from "react-router-dom";
import History from "../components/AccountAndPayments/History";
import OverUsage from "../components/AccountAndPayments/OverUsage";
import Payment from "../components/AccountAndPayments/Payment";

const AccountAndPayments = () => {
	const data = useLoaderData();
	const userPlan = data[1].data.data;
	const [currentPlan, setCurrentPlan] = useState(null);

	return (
		<div className="bg-purpleSecondary h-full">
			<p className="text-4xl  tracking-wide md:p-7 p-3 border-b-[1px] border-gray">
				Account & Payments
			</p>
			<div className="py-7">
				<Status />
			</div>
			<Plans
				userPlan={userPlan}
				planWord={true}
				setCurrentPlan={setCurrentPlan}
			/>
			{userPlan && (
				<OverUsage
					setCurrentPlan={setCurrentPlan}
					userPlan={userPlan}
				/>
			)}
			{currentPlan && (
				<Payment
					currentPlan={currentPlan}
					setCurrentPlan={setCurrentPlan}
				/>
			)}
			<History data={data} />
		</div>
	);
};

export default AccountAndPayments;

import { countriesLatLong } from "./getCountriesCor";

export const convertSecondsToTime = (seconds) => {
	if (seconds < 0) {
		return;
	}

	const hours = Math.floor(seconds / 3600);
	seconds %= 3600;
	const minutes = Math.floor(seconds / 60);
	const remainingSeconds = seconds % 60;

	const timeParts = [];
	if (hours > 0) timeParts.push(`${hours} Hour${hours > 1 ? "s" : ""}`);
	if (minutes > 0)
		timeParts.push(`${minutes} Minute${minutes > 1 ? "s" : ""}`);
	if (remainingSeconds > 0 || timeParts.length === 0)
		timeParts.push(
			`${remainingSeconds} Second${remainingSeconds > 1 ? "s" : ""}`
		);

	return timeParts.join(", ");
};

export const formatCountriesData = (data) => {
	if (!data.data) return [];
	const countries = countriesLatLong;
	const dataModified = [];

	Object.keys(data.data.country_views).forEach((c) => {
		const country = countries.filter((co) => co.alpha2 === c)[0];
		dataModified.push({
			id: c,
			name: country.country,
			top: country.latitude,
			left: country.longitude,
			views: data.data.country_views[c],
			time: convertSecondsToTime(data.data.country_watch_time[c]),
		});
	});
	return dataModified;
};

import React from "react";
import { Link } from "react-router-dom";

const NeedHelp = () => {
	return (
		<div className="need-help bg-cover bg-center">
			<div className="w-11/12 mx-auto py-60 flex flex-col gap-10">
				<p className="xl:w-2/3 text-5xl fon-bold">
					Need help with viedo DRM?
				</p>
				<div>
					<Link
						to="/contact-us"
						className="button !bg-yellow !bg py-3 px-14 text-black"
					>
						GET IN TOUCH
					</Link>
				</div>
			</div>
		</div>
	);
};

export default NeedHelp;

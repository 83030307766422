import { faCheck, faEdit, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import TimeTextInput from "./TimetextInput";

const VideoListItem = ({
	id,
	inputs,
	label,
	data,
	setData,
	editable,
	max = 0,
	checkLabel,
	checkDuplicated,
}) => {
	const [edit, setEdit] = useState(false);

	const editItem = (e) => {
		e.preventDefault();
		setEdit(false);
		const labelValue =
			e.target[String("data" + label)].value.trim() ||
			e.target[String("data" + label)].placeholder;

		if (checkLabel && !checkLabel(labelValue)) {
			e.target[String("data" + label)].value = data.filter(
				(d) => d.id === id
			)[0].label;
			return;
		}

		if (checkDuplicated) {
			const filter = data.filter(
				(d) => d.label === labelValue && d.id !== id
			);
			if (filter.length) {
				e.target[String("data" + label)].value = data.filter(
					(d) => d.id === id
				)[0].label;
				return;
			}
		}
		const inputsArray = [];
		inputs?.forEach((i) => {
			inputsArray.push(
				e.target[String("data" + i)].value.trim() ||
					e.target[String("data" + i)].placeholder
			);
		});

		setData((data) =>
			data.map((d) => {
				if (d.id === id) {
					d.inputs = inputsArray;
					d.label = labelValue;
					return d;
				} else {
					return d;
				}
			})
		);
	};

	const deleteItem = () => {
		setData((data) => data.filter((d) => d.id !== id));
	};

	return (
		<form
			className="flex items-stretch gap-5 md:flex-nowrap flex-wrap"
			onSubmit={editItem}
		>
			{inputs && Array.isArray(inputs)
				? inputs.map((i, key) => (
						<input
							type="text"
							className="p-2 bg-grayLight font-normal w-20"
							key={key}
							name={String("data" + i)}
							placeholder={i}
							disabled={!edit}
						/>
				  ))
				: inputs !== undefined && (
						<TimeTextInput
							disabled={!editable}
							defaultValue={inputs}
							max={max}
						/>
				  )}
			<input
				type="text"
				className="p-2 bg-grayLight font-normal flex-1"
				name={String("data" + label)}
				placeholder={label}
				disabled={!edit}
			/>
			{editable && (
				<button
					type={!edit ? "submit" : "button"}
					className="button-lg h-10 w-10 !bg-green"
					onClick={() => setEdit(!edit)}
				>
					<FontAwesomeIcon icon={edit ? faCheck : faEdit} />
				</button>
			)}
			<button
				type="button"
				className="button-lg h-10 w-10 !bg-pink"
				onClick={deleteItem}
			>
				<FontAwesomeIcon icon={faTimes} />
			</button>
		</form>
	);
};

export default VideoListItem;

import React, { useEffect, useState } from "react";
import Avatar from "../Avatar";
import { getUser } from "../../utils/getUser";
import axios from "axios";

const AvatarDetails = ({ user_color, user_background_color }) => {
	const [loading, setLoading] = useState(false);
	const [done, setDone] = useState(false);
	const [error, setError] = useState(false);
	useEffect(() => {
		if (done) {
			setTimeout(() => {
				setDone(false);
			}, 2000);
		}
	}, [done]);
	const [activeAvatar, setActiveAvatar] = useState({
		fill: user_color,
		background: user_background_color,
	});

	const handleSubmit = () => {
		setLoading(true);
		setDone(false);
		setError(false);
		const user = getUser();
		const formData = new FormData();
		formData.append("user_id", user);
		formData.append("user_color", activeAvatar.fill);
		formData.append("user_background_color", activeAvatar.background);
		axios
			.post("/edit_avatar.php", formData)
			.then((response) => {
				if (response.data.status === "success") {
					setDone(true);
				} else {
					setError("An error occurred, Please try again!");
				}
			})
			.catch(() => {
				setError("An error occurred, Please try again!");
			})
			.finally(() => {
				setLoading(false);
			});
	};
	return (
		<div className="flex flex-col">
			<p className="font-bold text-xl mb-5">Avatar</p>
			<div className="p-5 bg-gray flex-1 justify-between flex flex-col items-center button-lg-no-active">
				<Avatar
					dimensions="10rem"
					fill={activeAvatar.fill}
					background={activeAvatar.background}
					parent={true}
				/>
				<div className="w-full">
					<p className="font-bold text-xl mb-5">Choose One:</p>
					<div className="flex justify-between items-center md:flex-nowrap flex-wrap *:w-1/3 gap-5">
						<div>
							<Avatar
								fill="#7E3DD9"
								background="#201226"
								dimensions="6rem"
								setActiveAvatar={setActiveAvatar}
							/>
						</div>
						<div>
							<Avatar
								fill="#201226"
								background="#7E3DD9"
								dimensions="6rem"
								setActiveAvatar={setActiveAvatar}
							/>
						</div>
						<div>
							<Avatar
								fill="#7E3DD9"
								background="#f7ee7f"
								dimensions="6rem"
								setActiveAvatar={setActiveAvatar}
							/>
						</div>
						<div>
							<Avatar
								fill="#f7ee7f"
								background="#7E3DD9"
								dimensions="6rem"
								setActiveAvatar={setActiveAvatar}
							/>
						</div>
					</div>
					<button
						type="submit"
						className="button-lg mt-7 h-12 w-full p-2 !bg-purplePrimary"
						onClick={handleSubmit}
					>
						{loading ? "LOADING..." : "UPDATE"}
					</button>
					{error && <p className="text-pink mt-5">{error}</p>}
					{done && (
						<p className="text-green mt-5">
							Avatar has been updated!
						</p>
					)}
				</div>
			</div>
		</div>
	);
};

export default AvatarDetails;

import React, { useEffect, useState } from "react";
import VideoList from "../VideoList";
import { compareArrays } from "../../utils/compareArrays";
import { getUser } from "../../utils/getUser";
import { checkIP } from "../../utils/checkIP";
import axios from "axios";

const AllowedIP = ({ allowed_ips, setData }) => {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);
	const [done, setDone] = useState(false);

	useEffect(() => {
		if (done) {
			setTimeout(() => {
				setDone(false);
			}, 2000);
		}
	}, [done]);

	const handleSubmit = async (data) => {
		setLoading(true);
		setError(false);
		setDone(false);
		try {
			const user = getUser();
			const refrence = JSON.parse(allowed_ips);
			const { removed, added } = compareArrays(refrence, data);

			const removeEndpoint = "/remove_allowed_ip.php";
			const addEndpoint = "/add_allowed_ip.php";

			let response;
			for (const chapter of removed) {
				const formData = new FormData();
				formData.append("user_id", user);
				formData.append("ip_address", chapter);
				response = await axios.post(removeEndpoint, formData);
			}
			for (const chapter of added) {
				const formData = new FormData();
				formData.append("user_id", user);
				formData.append("ip_address", chapter.label);
				response = await axios.post(addEndpoint, formData);
			}
			if (response) {
				setData((data) => {
					data.allowed_ips = JSON.stringify(
						response.data?.allowed_ips || []
					);
					return data;
				});
				if (response.data.status === "success") {
					setDone(true);
				} else {
					setError("An error occurred, Please try again!");
				}
				return;
			}
			setDone(true);
		} catch (error) {
			setError("An error occurred, Please try again!");
		} finally {
			setLoading(false);
		}
	};
	return (
		<>
			<p className="my-5 text-lg font-bold ">Allowed IPs</p>
			<VideoList
				editable={false}
				checkLabel={checkIP}
				inputs={[]}
				label="ip"
				placeholder="Example: 192.168.*"
				onSubmit={handleSubmit}
				submitLoading={loading}
				checkDuplicated={true}
				IPDefaultValues={JSON.parse(allowed_ips)}
			/>
			<p className="text-sm my-5">
				* Whitelisted IPs only will be able to download original video's
				file, if you keep it blank you won't be able to download the
				files.
			</p>
			{error && <p className="text-pink">{error}</p>}
			{done && (
				<p className="text-green">
					Allowed IP Option has been updated!
				</p>
			)}
		</>
	);
};

export default AllowedIP;

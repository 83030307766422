import React, { useContext, useEffect, useRef, useState } from "react";
import Menu from "./components/Menu";
import { Outlet, useLocation } from "react-router-dom";
import Footer from "./components/Footer";
import { MenuBackground } from "./context/MenuContext";

const MainLayout = () => {
	const { menuBackground, setMenuBackground } = useContext(MenuBackground);
	const location = useLocation();
	useEffect(() => {
		switch (location.pathname) {
			case "/contact-us":
				setMenuBackground("#323232");
				break;
			case "/pricing":
				setMenuBackground("#323232");
				break;
			case "/security-comparison":
				setMenuBackground("#323232");
				break;
			default:
				setMenuBackground(0);
				break;
		}
	}, [location, setMenuBackground]);
	const menu = useRef(null);
	const [menuHeight, setMenuHeight] = useState(null);
	useEffect(() => {
		if (menu.current) {
			setMenuHeight(menu.current.firstChild.offsetHeight);
		}
	}, [menu]);

	return (
		<div className="min-h-screen flex flex-col">
			<div ref={menu}>
				<Menu />
			</div>
			<main
				className="flex-1"
				style={{
					marginTop:
						menuHeight && menuBackground ? menuHeight + "px" : "",
				}}
			>
				<Outlet />
			</main>
			<Footer />
		</div>
	);
};

export default MainLayout;

import React, { useState } from "react";

const VideoOption = ({ name, description, status, keyName }) => {
	const [optionStatus, setOptionStatus] = useState(status);
	return (
		<div className="flex justify-between items-center">
			<input type="hidden" name={keyName} value={optionStatus} />
			<div className="flex items-center gap-3">
				<p>{name}</p>
				{description && (
					<div className="relative h-5 w-5 group flex justify-center items-center bg-purplePrimary rounded-full">
						<div className="hidden absolute top-0 left-0 z-50 -translate-y-full w-72 group-hover:block p-3 button-lg-no-active !bg-purplePrimary">
							<p>{description}</p>
						</div>
						<p className="text-gray text-sm">?</p>
					</div>
				)}
			</div>
			<div
				className={`h-5 w-10 p-2 ${
					optionStatus ? "!bg-purplePrimary" : "!bg-purplePrimary/50"
				} relative cursor-pointer`}
				onClick={() => setOptionStatus(!optionStatus)}
			>
				<div
					className={`absolute top-1/2 -translate-y-1/2 ${
						optionStatus ? "left-1" : "right-1"
					} h-3 w-3 bg-purpleSecondary`}
				></div>
			</div>
		</div>
	);
};

export default VideoOption;

import React from "react";
import Wolvy from "./Wolvy";

const Footer = () => {
	return (
		<footer className="bg-gray py-10">
			<Wolvy />
			<div className="flex *:flex-1 justify-between">
				<div className="text-center">
					<p>&copy; 2024 Wolvy All Rights Reserved.</p>
				</div>
			</div>
		</footer>
	);
};

export default Footer;

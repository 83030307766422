import axios from "axios";
import React, { useState } from "react";
import {
	Bar,
	BarChart,
	CartesianGrid,
	Legend,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
} from "recharts";
import { generateData } from "../utils/chartData";
import { getUser } from "../utils/getUser";

const AnalyticChart = ({ result }) => {
	const [analytics, setAnalytics] = useState(result[0]);
	const [range, setRange] = useState("year");
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState();

	const getChartData = (value) => {
		setLoading(false);
		setError(false);
		const user = getUser();
		const formData = new FormData();
		formData.append("user_id", user);
		formData.append("time_frame", value);
		axios
			.post("/analytics_overview.php", formData)
			.then((response) => {
				if (response.data.status === "success") {
					setAnalytics(response.data);
				} else {
					setError("An error occurred, Please try again!");
				}
			})
			.catch(() => {
				setError("An error occurred, Please try again!");
			})
			.finally(() => {
				setLoading(false);
			});
	};

	return (
		<div className="bg-gray p-5">
			<div className="flex justify-between items-center">
				<p className=" text-lg font-bold">Analytics Overview</p>
				<select
					name="period"
					className="bg-black p-1 w-52"
					id="period"
					onChange={(e) => {
						setRange(e.target.value);
						getChartData(e.target.value);
					}}
					disabled={loading}
				>
					<option value="year">Year</option>
					<option value="month">Month</option>
					<option value="day">Day</option>
				</select>
			</div>
			<p className=" text-2xl my-5">
				Total Videos: {analytics?.video_count}
			</p>
			<ResponsiveContainer width="100%" height={500}>
				<BarChart data={generateData(range, analytics.usage_data)}>
					<CartesianGrid />
					<XAxis tick={false} dataKey="date" />
					<YAxis />
					<Tooltip
						contentStyle={{
							backgroundColor: "#3d3d3d",
							borderRadius: "5px",
							border: "1px solid #333",
							boxShadow: "0 0 20px #3d3d3d",
						}}
						cursor={{ fill: "transparent" }}
						wrapperStyle={{
							color: "#000",
						}}
					/>
					<Legend />
					<Bar dataKey="Storage" fill="#7E3DD9" />
					<Bar dataKey="Bandwidth" fill="#f7ee7f" />
				</BarChart>
			</ResponsiveContainer>
			{error && <p className="text-pink">{error}</p>}
		</div>
	);
};

export default AnalyticChart;

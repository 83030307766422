import CryptoJS from "crypto-js";

// Function to create a hash of the key
const simpleHash = (key) => {
	return CryptoJS.SHA256(key).toString(CryptoJS.enc.Hex);
};

// Function to encrypt a text
const encrypt = (text, key) => {
	const hashedKey = CryptoJS.enc.Hex.parse(simpleHash(key));
	const iv = CryptoJS.enc.Hex.parse("00000000000000000000000000000000"); // Static IV

	const encrypted = CryptoJS.AES.encrypt(text, hashedKey, {
		iv: iv,
		mode: CryptoJS.mode.CBC,
		padding: CryptoJS.pad.Pkcs7,
	});

	return encrypted.toString(); // Return encrypted text as a Base64 string
};

// Function to decrypt an encrypted text
const decrypt = (encrypted, key) => {
	const hashedKey = CryptoJS.enc.Hex.parse(simpleHash(key));
	const iv = CryptoJS.enc.Hex.parse("00000000000000000000000000000000"); // Static IV

	const decrypted = CryptoJS.AES.decrypt(encrypted, hashedKey, {
		iv: iv,
		mode: CryptoJS.mode.CBC,
		padding: CryptoJS.pad.Pkcs7,
	});

	return decrypted.toString(CryptoJS.enc.Utf8); // Return the decrypted text as a UTF-8 string
};

export const encode = (text, key = "AhmedAraby123") => {
	if (!text || !key) {
		return "Error";
	}

	const encrypted = encrypt(text, key);
	const midPoint = Math.ceil(encrypted.length / 2);

	const part1 = encrypted.substring(0, midPoint);
	const part2 = encrypted.substring(midPoint);

	return { part1, part2 };
};

export const decode = (part1, part2, key = "AhmedAraby123") => {
	if (!part1 || !part2 || !key) {
		return "Error";
	}

	try {
		const encrypted = part1 + part2;
		return decrypt(encrypted, key);
	} catch (error) {
		throw new Error("Invalid key or corrupted data");
	}
};

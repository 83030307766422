import { faBars, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import Avatar from "./Avatar";
import { getUser } from "../utils/getUser";
import axios from "axios";

const Navbar = ({ setMenu, avatarData, showSearch, setShowSearch }) => {
	const [search, setSearch] = useState(false);
	const [loading, setLoading] = useState(false);
	const typingTimeoutRef = useRef(null);
	const handleSearch = (e) => {
		const value = e.target.value;

		if (typingTimeoutRef.current) {
			clearTimeout(typingTimeoutRef.current);
		}

		typingTimeoutRef.current = setTimeout(() => {
			if (value.length >= 3) {
				setLoading(true);
				setShowSearch(true);
				const user = getUser();
				const formData = new FormData();
				formData.append("user_id", user);
				formData.append("search_term", value);
				axios
					.post("/search.php", formData)
					.then((response) => {
						if (response.data.status === "success") {
							setSearch(response.data.videos);
						}
					})
					.catch(() => {})
					.finally(() => {
						setLoading(false);
					});
			}
		}, 1000);
	};

	return (
		<nav className="pe-5 relative py-2 flex justify-between items-center bg-gray">
			<div
				className="md:hidden block cursor-pointer px-5 text-xl"
				onClick={() => setMenu(true)}
			>
				<FontAwesomeIcon icon={faBars} />
			</div>
			<div className="flex items-center gap-3 ms-5 bg-grayLight rounded-lg px-3 py-2">
				<FontAwesomeIcon icon={faSearch} />
				<input
					className="bg-transparent text-sm md:w-72 w-36"
					type="text"
					placeholder="Search (type 3 chars at least to search)"
					onInput={handleSearch}
				/>
			</div>
			{showSearch && search.length ? (
				<div className="absolute border-2 max-h-96 overflow-auto *:cursor-pointer flex flex-col bottom-0 left-2 translate-y-full z-50 p-5 bg-grayLight gap-5">
					{search.map((s, i) => (
						<React.Fragment key={i}>
							<Link
								to={`/file/${s.wolvy_id}`}
								className="flex justify-between items-center gap-5 *:cursor-pointer"
							>
								<div className="border-2">
									{s.poster ? (
										<img
											src={s.poster}
											alt={s.title}
											className="object-cover h-20 aspect-square"
											draggable={false}
										/>
									) : (
										<div className="h-52 w-full bg-white"></div>
									)}
								</div>
								<p className="">{s.title}</p>
							</Link>
							{i + 1 !== search.length && (
								<div className="w-full h-[1px] bg-white"></div>
							)}
						</React.Fragment>
					))}
				</div>
			) : (
				showSearch && (
					<div className="absolute border-2 flex bottom-0 left-2 translate-y-full z-50 p-5 bg-grayLight w-72">
						<p>No Videos Avaliable</p>
					</div>
				)
			)}
			<Link to="/account">
				<Avatar
					fill={avatarData.settings.user_color}
					background={avatarData.settings.user_background_color}
					dimensions="3rem"
					padding={1}
				/>
			</Link>
		</nav>
	);
};

export default Navbar;

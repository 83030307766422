export const checkFileClicked = (event, targetClass, stopClass) => {
	let current = event.target;

	while (current) {
		if (current.classList.contains(targetClass)) {
			return {
				id: current.getAttribute("aria-description"),
				title: current.getAttribute("aria-label"),
			};
		}
		if (current.classList.contains(stopClass)) {
			return false;
		}
		current = current.parentElement;
	}

	return false;
};
